<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import GenericForm from "generic-form";
import UserPasswordEditingForm from 'user-password-editing-form';

export default {
  mixins: [fandomBasicMixin],
  components: {
    UserPasswordEditingForm,
    GenericForm
  },
  computed: {
    componentStyle() {
      return {
        "background-color": this.content.background_color
      };
    }
  }
};
</script>

<template>
  <div class="w-100" user-edit-component :style="componentStyle">
    <div class="container">
      <div class="row justify-content-center">
        <!-- margin management -->
        <div class="col-sm-12 col-md-8 col-sm-6 my-5">
          <div class="mb-4">
            <h3 class="mb-3 user-edit-title">{{ft("globals.user_profile.user_edit_title")}}</h3>
            <generic-form :content="content" :opt-container-content="containerContent"></generic-form>
          </div>
          <h3 class="mb-3 user-edit-title">{{ft("globals.user_profile.password_edit_title")}}</h3>
          <user-password-editing-form :content="content" :opt-container-content="containerContent"></user-password-editing-form>
        </div>  
      </div>
    </div>
  </div>
</template>

<style lang="scss">
</style>
