import { render, staticRenderFns } from "./video-popup.vue?vue&type=template&id=d214afa8&scoped=true"
import script from "./video-popup.vue?vue&type=script&lang=js"
export * from "./video-popup.vue?vue&type=script&lang=js"
import style0 from "./video-popup.vue?vue&type=style&index=0&id=d214afa8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d214afa8",
  null
  
)

export default component.exports