<template>
  <div v-if="positionChanges != 0" class="mr-3">
    <div v-if="positionChanges == null" class="text-primary badge badge-pill badge-new">{{ ft("globals.new") }}</div>
    <div v-else-if="positionChanges > 0" class="badge badge-pill badge-up">
      <i class="fa-regular fa-arrow-up"></i>
      <span>{{ positionChanges }}</span>
    </div>
    <div v-else-if="positionChanges < 0" class="badge badge-pill badge-down">
      <i class="fa-regular fa-arrow-down"></i>
      <span>{{ Math.abs(positionChanges) }}</span>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    positionChanges: {
      type: Number|null
    }
  }
}
</script>

<style lang="scss" scoped>
.badge {
  background-color: $gray-200;
  font-size: 1rem;
  font-weight: 300;
  
  &-up {
    color: $success;
  }

  &-down {
    color: $danger;
  }

  &-new {
    color: $info;
  }
}
</style>