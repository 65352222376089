<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import RankingPositionBadge from './ranking-position-badge.vue';

export default {
  mixins: [fandomBasicMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    rankingConfig: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    isUserRewardType() {
      return this.rankingConfig.type == 'user_reward';
    },
    itemThumbUrl() {
      const imageUrl = Fandom.getContentResponsiveImageURL("thumbnail", this.item.content_cache) ?? Fandom.getContentResponsiveImageURL("image", this.item.content_cache);
      return imageUrl.replace('/original/', '/thumb/');
    },
    ownerInitial() {
      return this.item.user.username[0];
    },
    userAvatarUrl() {
      return typeof this.item?.user?.avatar == "string" ? this.item.user.avatar : this.item?.user?.avatar?.url;
    },
    isCurrentUserItem() {
      return this.item.user.id == this.globalState.pageModel.user.id;
    }
  }
};
</script>

<template>
  <div ranking-item-component>
    <component :is="item.content_cache.slug ? 'a' : 'div'" :href="item.content_cache.slug">
      <div class="container" :class="isCurrentUserItem ? 'bg-light' : 'bg-white'">
        <div class="row border-bottom border-gray-dark py-4 py-md-2 align-items-center" :class="{'border-top': item.position==1}">
          <div class="col-md-1 d-flex flex-md-column align-items-center pb-1 pb-md-0">
            <p class="h2 text-lg-center text-primary mb-0">#{{item.position}}</p>
            <ranking-position-badge class="ml-3 mt-md-3" :position-changes="item.position_change"></ranking-position-badge>
            <div class="d-md-none flex-grow-1">
              <div class="d-flex align-items-center justify-content-end">
                <span class="font-weight-bold">
                  <span>{{ item.value }}</span>
                </span>
                <i class="pl-2 fa-heart fas fa-lg text-primary"></i>
              </div> 
            </div>
          </div>
          <div id="img-wrapper" class="col-md-2">
            <div class="overflow-hidden">
              <div class="ar-1by1 h-100 bg-cover-center w-100 position-relative animate-zoom-md" :style="{ 'background-image': itemThumbUrl}">
                <div class="gradient d-md-none"></div>
                <div class="avatar-container row flex-row-reverse d-md-none align-items-center position-absolute pt-2">
                  <div v-if="userAvatarUrl" class="col-auto mr-0 pr-4 align-self-center justify-content-right"><img :src="userAvatarUrl" class="size-sm avatar"></div>
                  <div v-else class="col-auto mr-0 pr-4 align-self-center justify-content-right">
                    <span class="text-white avatar-text">{{ownerInitial}}</span>
                  </div>
                  <div class="col-auto pl-5 pr-0 mx-0 align-self-center justify-content-right text-white"><span class="small px-0 mx-0 font-weight-bold" >{{item.user.username}}</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-5 pt-2"> 
            <div class="align-items-center d-none d-md-flex">
              <div v-if="userAvatarUrl" class="align-items-end justify-content-center"><img :src="userAvatarUrl" class="avatar size-sm"></div>
              <div v-else class="align-items-end justify-content-center"><span class="text-white avatar-text">{{ownerInitial}}</span></div>
              <div class="py-1 pl-3 align-items-center justify-content-center"><span class="small font-weight-bold" >{{item.user.username}}</span></div>
            </div> 
            <p class="h5 pt-2 pt-md-3">{{item.content_cache.title}}</p>
          </div>
          <div class="col-lg-3 col-md-4 d-none d-md-block"> 
            <div class="d-flex justify-content-end align-items-center">
              <span class="font-weight-bold">
                <span>{{ item.value }}</span>
              </span>
              <i class="pl-2 fa-heart fas fa-lg text-primary"></i>
            </div> 
          </div>
        </div>
      </div>
    </component>
  </div>
</template>

<style lang="scss" scoped>
[ranking-item-component] {
  a {
    color: $dark !important;
    text-decoration: none !important;
  }

  @include media-breakpoint-up(md) {
    .animate-zoom-md {
      transition: transform 0.75s linear;
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .avatar-container {
    right: 0;
  }

  .line-clamp{
    display: -webkit-box; 
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical; 
    overflow: hidden;
  }

  .gradient {
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    height: 25%;
    background-image: linear-gradient(0deg, rgba(238, 238, 238, 0), rgba(0, 0, 0, 0.3));
    position: absolute;
  }

  .avatar {
    height: 2.8rem;
    width: 2.8rem;
    border-radius: 50%;
    z-index: 1;
    background-color: $primary;
  }

  .avatar-text {
    @extend .avatar;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
