<script>
import { get } from "lodash";
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import postscribe from "postscribe";
import TextSection from 'text-section';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  components: {
    TextSection
  },
  data() {
    return {
      paddingYDefault: 5
    };
  },
  mounted() {
    Vue.nextTick(() => {
      if (this.content.snippet) {
        postscribe(`#${this.snippetId}`, this.content.snippet);
      } 
    });
  },
  computed: {
    snippetId() {
      return `#embed-advanced-${this.contentId.toString().replace("|", "-")}`;
    },
    columnClasses() {
      const size = get(this, ["content", "width"], 8);
      const textAlign = get(this, ["content", "text_align"], "left");
      return [`col-md-${size}`, `text-${textAlign}`];
    },
    containerClasses() {
      const classes = [];
      if (this.propagateBackground) {
        classes.push("themed-item");
        classes.push(this.paddingY);
      }
      return classes;
    },
    containerStyles() {
      return {
        "background-image": this.propagateBackground ? this.backgroundImage : ""
      };
    },
    contentClasses() {
      const classes = [];
      if (!this.propagateBackground) {
        classes.push("themed-item");
        classes.push(this.paddingY);
      }

      if (this.centered) {
        classes.push("justify-content-center");
      }

      if (this.content.height) {
        classes.push("align-items-center");
      }

      return classes;
    },
    contentStyles() {
      return {
        "min-height": this.content.height,
        "background-image": this.propagateBackground ? "" : this.backgroundImage
      };
    },
    centered() {
      return get(this, ["content", "centered_column"], true);
    },
    backgroundImage() {
      return this.getContentResponsiveImageURL("background_image");
    },
    propagateBackground() {
      return get(this, ["content", "propagate_background"], false);
    }
  }
};

</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" :id="anchorId" :style="theme" v-if="isContentVisibleToUser()" stripe-text-component>
    <div :id="snippetId" v-if="content.snippet"></div>
    <div class="w-100 bg-cover-center" :class="containerClasses" :style="containerStyles">
      <div class="container">
        <div class="row bg-cover-center" :class="contentClasses" :style="contentStyles">
          <div class="col-12" :class="columnClasses">
            <text-section :content="content" :opt-container-content="containerContent"></text-section>
            <slot name="stripe-text-custom-text"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
