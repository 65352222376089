<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  data: function() {
    return {
      resultContent: null,
      searchText: "",
      activeSearch: "",
      contentFiltering: []
    };
  },
  mounted() {
    Vue.nextTick(() => {    
      Fandom.onEvent("grid-filter-apply", (data) => {
        if (data.handlerContents.indexOf(this.content.name) > -1) {
          this.contentFiltering = data.contentFiltering;
          this.applyFilters();
        }
      });

      const searchText = Fandom.getUrlParameter("q");
      if (Fandom.exists(searchText)) {
        this.searchText = searchText;
        this.applyFilters();
      }
    });
  },
  methods: { 
    getPrimaryButtonClass: Fandom.getPrimaryButtonClass,     
    applyFilters() {
      this.activeSearch = this.searchText;
      
      let location = window.location.href;
      location = Fandom.updateQueryStringParameter(location, "q", this.activeSearch);
      window.history.pushState('', '', location);

      this.mayDeleteResultContentChildren();
      this.getSearchableContents(false);
    },
    mayDeleteResultContentChildren() {
      if (Fandom.exists(this.resultContent)) {
        if (Fandom.exists(this.resultContent.children)) {
          for (const chidlName of this.resultContent.children) {
            delete globalState.pageModel.name_to_content[chidlName];
          }  
        }   
        this.resultContent = null;
      }
    },
    getSearchableContents(appendContents) {
      const successBlock = (data) => {
        Vue.set(this, "resultContent", globalState.pageModel.name_to_content[data.main_content_name]);
      }
      const params = this.filtersToGetContentsParams();

      Fandom.getSearchableContents(successBlock, params, appendContents);
    },
    appendContents() {
      const successBlock = (data) => {
        this.resultContent = globalState.pageModel.name_to_content[data.main_content_name];
      }
      const params = this.filtersToGetContentsParams(this.filters);
      params["exclude_content_ids"] = this.resultContent.children.map(c => this.getContent(c).id);
      Fandom.getSearchableContents(successBlock, params, true);
    },
    filtersToGetContentsParams() {
      let contentFiltering = [];
      if (this.contentFiltering && this.contentFiltering.length > 0) {
        contentFiltering = contentFiltering.concat([this.contentFiltering]);
      }
      if (!!this.content.filter_contents && this.content.filter_contents.length > 0) {
        contentFiltering = contentFiltering.concat([this.content.filter_contents]);
      }

      const params = {
        layout: "search-result",
        include_parent_names: true,
        include_children_count: true,
        content_filtering: contentFiltering
      };
      if (Fandom.exists(this.resultContent)) {
        params.main_content_name = this.resultContent.name;
      }
      if (Fandom.exists(this.searchText)) { 
        params.search_text = Fandom.searchTextToBackendFormat(this.searchText);
      }
      return params;
    },
    redirect(slug) {
      window.location.href = slug;
    },
    itemTitle(item) {
      const content = this.getContent(item);
      return content.seo && content.seo.title ? content.seo.title : content.title;
    }
  },
};

</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" stripe-search-component>
    <div class="heading">
      <div class="container">
        <div class="row">
          <div class="col-md-10 my-5">
            <h3 class="search-heading font-weight-normal">{{ft("globals.you_searched_for")}}</h3>
            <input type="text" class="custom-input" v-model="searchText" :placeholder="ft('globals.you_searched_for_placeholder')" v-on:keyup.enter="applyFilters()">
          </div>
        </div>
      </div>
    </div>
    <div v-if="resultContent" class="">
      <div class="container">
        <div class="results-count text-muted text-uppercase my-5">
          <<{{resultContent.children_count}}>> {{ft("globals.results_for")}} <<{{activeSearch}}>>    
        </div>
      </div>
      <div v-for="child in resultContent.children">
        <div class="container child py-4 cursor-pointer" @click="redirect(getContent(child).slug)">
          <div class="deco text-uppercase small text-primary pb-2" v-if="getDecorator(getContent(child), 'default')">
            {{getDecorator(getContent(child), "default").title}}
          </div>
          <div class="child-title" v-if="itemTitle(child)">
            {{itemTitle(child)}}
          </div>
          <div class="child-subtitle" v-if="getContent(child).subtitle">
            {{getContent(child).subtitle}}
          </div>
        </div>
        <div class="w-100 border-bottom"  v-if="resultContent.children[resultContent.children.length - 1] != child"></div>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center container py-5" v-if="resultContent && resultContent.has_more_children"> 
      <button class="btn btn-primary" @click="appendContents()">{{ft("globals.load_more")}}</button>
    </div>
  </div>
</template>

<style lang="scss">
[stripe-search-component] {
  .heading {
    background-color: $gray-200;
  }

  .child:hover {
    .child-title, .child-subtitle {
      color: $gray-400;
    }
  }

  .child-title {
    font-size: 1.25rem;
    transition: color $animation-time-fast linear;
  }

  .child-subtitle {
    transition: color $animation-time-fast linear;
  }

  .custom-input {
    height: 3.75rem;
    padding: 0;
    font-size: 2.25rem;
    text-transform: uppercase;
    color: $dark;
    background-color: transparent;
    border: 0;
    outline-width: 0px;

    &::placeholder {
      color: $gray-400;
    }
  }

}
</style>