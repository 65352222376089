<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  data: function() {
    return {
      currentTab: this.content.tabs[0]
    };
  },
  mounted() {
  },
  methods: {
    isItemActive(item) {
      return this.currentTab.$id == item.$id;
    }
  },
  computed: {
    theme() {
      return this.content.theme ? `${this.content.theme}-theme bg-${this.content.theme} navbar-${this.content.theme}` : "light-theme bg-light navbar-light";
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" navbar-tabs-component>
    <nav :class="theme" class="navbar navbar-expand-md d-flex justify-content-end py-3">
      <button class="navbar-toggler" type="button" data-toggle="collapse" :data-target="`#navbar-secondary-${content.$id || content.id}`" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" :id="`navbar-secondary-${content.$id || content.id}`">
        <div class="navbar-nav mx-auto">
          <li class="nav-item mx-md-3" v-for="item in content.tabs" :key="`item-${item.$id}`" :class="{'active': isItemActive(item)}">
            <a class="nav-link" href="javascript: void(0)" @click.prevent="currentTab = item">{{item.title}}</a>
          </li>
        </div>
      </div>
    </nav>
    <component :is="stripe.layout" :content="stripe" v-for="stripe in currentTab.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
  </div>
</template>

<style lang="scss" scoped>
[navbar-tabs-component] {
  .dark-theme {
    background-color: $dark;

    .nav-link {
      color: rgba($white, .5) !important;
    } 
  }

  &.sticky {
    position: sticky;
    z-index: 1;
  }
}
</style>

