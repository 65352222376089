<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { interactionMixin } from 'src/modules/interactions_module.js';
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, interactionMixin],
  data: function () {
    return {
      paddingYDefault: 5
    };
  },
  computed: {
    columnsMap() {
      let columnsMap = [false, false, false, false];
      this.content.table.forEach((item) => {
        columnsMap[0] = columnsMap[0] || Fandom.exists(item.text_column_1);
        columnsMap[1] = columnsMap[1] || Fandom.exists(item.text_column_2);
        columnsMap[2] = columnsMap[2] || Fandom.exists(item.text_column_3);
        columnsMap[3] = columnsMap[3] || Fandom.exists(item.attachment_column);
        columnsMap[4] = columnsMap[4] || Fandom.exists(item.date_column);
      });
      return columnsMap;
    },
    items() {
      return this.content.table;
    },
    columnClasses() {
      const columnWidth = Fandom.exists(this.content.width) ? this.content.width : 8;
      return [
        `col-lg-${columnWidth}`
      ];
    },
    visibleItems() {
      return this.items.filter(item => this.checkItemVisible(item));
    }
  },
  methods: { 
    checkItemVisible(item) {
      return item.text_column_1 || item.text_column_2 || item.text_column_3;
    },
    downloadInteractionHandler(item) {
      if (Fandom.exists(item.interaction)){
        let attachment = item.attachment_column;
        this.updateDownloadInteraction(this.containerContent, this.content, item.interaction, null, attachment.url);
      }
    },
    formattedDate(date) {
      const fDate = new Date(date);
      const month = fDate.getMonth() < 9 ? `0${fDate.getMonth() + 1}` : `${fDate.getMonth() + 1}`;
      const day = fDate.getDate() < 10 ? `0${fDate.getDate()}` : `${fDate.getDate()}`;
      return `${fDate.getFullYear()}/${month}/${day}`;
    },
  },
  watch: {
    'filters.year': function() {
      this.filters.month = "";
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="[contentType, paddingY]" :id="anchorId" :style="{'background-color': content.background_color}" table-simple-component>
    <div class="py-5 container">
      <div class="row justify-content-center">
        <div :class="columnClasses">
          <div class="table-responsive bg-white">
            <table class="table table-bordered mb-0" >
              <tbody>
                <tr v-for="(item, index) in visibleItems" :key="`item-${index}`">
                  <td v-if="columnsMap[4]" class="p-4 cell date">
                    {{formattedDate(item.date_column)}}
                  </td>
                  <td v-if="columnsMap[0]" class="p-4 cell" v-html="item.text_column_1"></td>
                  <td v-if="columnsMap[1]" class="p-4 cell" v-html="item.text_column_2"></td>
                  <td v-if="columnsMap[2]" class="p-4 cell" v-html="item.text_column_3"></td>
                  <td v-if="columnsMap[3]" align="middle" class="p-4 attachment-column cell">
                    <component v-if="item.attachment_column" :is="item.interaction ? 'span' : 'a'" :href="item.interaction ? 'javascript:void(0)' : item.attachment_column.url" :target="item.interaction ? '_self' :  '_blank'" class="cursor-pointer text-dark" @click="item.interaction ? downloadInteractionHandler(item) : null">
                      <i class="fal fa-download"></i>
                    </component>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> 
    </div> 
  </div>
</template>

<style lang="scss">
[table-simple-component] { 

  .cell {
    vertical-align: middle;

    &.date {
      width: 8rem;
    }
  }

  .attachment-column {
    width: 4rem;
  }

}
</style>