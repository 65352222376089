<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  data: function() {
    return {
      contentStyles: {}
    };
  },
  mounted() {
    Vue.nextTick(() => {
      if (this.content.sticky) {
        Vue.set(this.contentStyles, "top", `${$("#main-navbar").outerHeight()}px`);
      }
    }); 
  },
  methods: {
    isItemActive(item) {
      const re = new RegExp(`^(${item.matching_name})$`);
      return re.test(globalState.pageModel.main_content_name);
    },
    getItemUrl(item) {
      if (item.url.url.startsWith("#")) {
        return "javascript: void(0)";
      } else if (this.content.preserve_url_params) {
        return item.url.url + '?' + window.location.search.substr(1);
      } else {
        return item.url.url;
      }
    },
    mayAnchorTo(item, event) {
      if (item.url.url.startsWith("#")) {
          let offset = $("#main-navbar").outerHeight();
          if (this.content.sticky) {
            offset += $(this.$el).outerHeight();
          }

          const position = $(item.url.url).offset().top - offset;
          $("html, body").animate({ scrollTop: position }, 0, "linear");

         event.preventDefault();
         return false;
      } else {
        return true;
      }
    },
  },
  computed: {
    theme() {
      return this.content.theme ? `${this.content.theme}-theme bg-${this.content.theme} navbar-${this.content.theme}` : "light-theme bg-light navbar-light";
    },
    contentClasses() {
      let classes = [this.contentType];
      if (this.content.sticky) {
        classes.push('sticky');
        $(this.$el).css("top", $("#main-navbar").outerHeight());
      }
      return classes;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentClasses" :style="contentStyles" navbar-secondary-component>
    <nav :class="theme" class="navbar navbar-expand-md d-flex justify-content-end py-3">
      <button class="navbar-toggler" type="button" data-toggle="collapse" :data-target="`#navbar-secondary-${content.$id || content.id}`" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" :id="`navbar-secondary-${content.$id || content.id}`">
        <div class="navbar-nav mx-auto">
          <li class="nav-item mx-md-3" v-for="item in content.items" :key="`item-${item.$id}`" :class="{'active': isItemActive(item)}">
            <a class="nav-link" :href="getItemUrl(item)" @click="mayAnchorTo(item, $event)" :target="getTarget(item.url)">{{item.url.title}}</a>
          </li>
        </div>
      </div>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
[navbar-secondary-component] {
  .dark-theme {
    background-color: $dark;

    .nav-link {
      color: rgba($white, .5) !important;
    } 
  }

  &.sticky {
    position: sticky;
    z-index: 1;
  }
}
</style>

