<template>
  <div>
    <div id="easyadmin-main-buttons" class="easyadmin-main-buttons-editing d-flex flex-column justify-content-around" hidden>
      <a 
        href="/easyadmin/content/edit/header" 
        class="easyadmin-main-button d-flex align-items-center justify-content-center" 
        target="_blank"
        @click="easyadminToggleInlineEditingButtons"
      >
        <span>H</span>
      </a>
      <a 
        :href="`/easyadmin/content/edit/${pageModel.main_content_name}`"
        class="easyadmin-main-button d-flex align-items-center justify-content-center"
        target="_blank"
        @click="easyadminToggleInlineEditingButtons"
      >
        <span>P</span>
      </a>
      <a 
        v-if="isAiContent"
        :href="`/easyadmin/ai/content/${pageModel.main_content_name}/edit`"
        class="easyadmin-main-button d-flex align-items-center justify-content-center"
        target="_blank"
        @click="easyadminToggleInlineEditingButtons"
      >
        <i class="fa-thin fa-microchip-ai"></i>
      </a>
      <a
        v-if="isLiveShopping"
        :href="`/easyadmin/live-events/control-room/${pageModel.main_content_name}`"
        class="easyadmin-main-button d-flex align-items-center justify-content-center"
        target="_blank"
        @click="easyadminToggleInlineEditingButtons"
      >
        <i class="fa-thin fa-clapperboard-play"></i>
      </a>
      <a 
        href="/easyadmin/content/edit/footer"
        class="easyadmin-main-button d-flex align-items-center justify-content-center"
        target="_blank"
        @click="easyadminToggleInlineEditingButtons"
      >
        <span>F</span>
      </a>
    </div>
    <a 
      href="javascript: void(0)"
      class="btn-easyadmin-inline-editing d-flex align-items-center justify-content-center"
      @click="easyadminToggleInlineEditingButtons"
    >
      <i class="fal fa-cogs"></i>
    </a>
  </div>
</template>

<script>
export default {
  props: ['content'],
  methods: {
    easyadminToggleInlineEditingButtons() {
      globalState.easyadminMode = !globalState.easyadminMode;
    }
  },
  computed: {
    pageModel() {
      return globalState.pageModel;
    },
    isAiContent() {
      return ['page-ai-interview'].includes(this.content.layout);
    },
    isLiveShopping() {
      return this.content.layout==='page-player';
    }
  }
}
</script>