<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data: function() {
    return {
    };
  },
  methods: {
    getImage(item){
      return item.image.url;
    },
    getTitle(item){
      return item.title;
    },
    getDescription(item){
      return item.description;
    },
    getUrlObj(item){
      return item.url;
    },
    getButton(item){
      return item.button;
    },
    getButtonClass(item){
      if (this.content.theme == "dark")
        return "btn-light";
      if (this.content.theme == "light")
        return "btn-dark";
      
      return item.button_class;
    },
    getLink(item){
      return item.button.url;
    },
    getUrlTitle(item){
      return item.button.title;
    },
    getUrlTarget(item){
      if (item.button.new_tab)
        return "_blank"
    }
  },
  computed: {
    skin() {
      return this.content.skin || '3';
    },
    skinSet() {
      const keypointClasses = [];
      const containerClasses = [];
      switch(this.skin) {
        case '2':
          keypointClasses.push('col-md-6 col-12');
          containerClasses.push('col-lg-8 col-md-10 col-12');
          break;
        case '3':
          keypointClasses.push('col-lg-4 col-md-6 col-12');
          containerClasses.push('col-lg-10 col-md-10 col-12');
          break;
        case '4':
          keypointClasses.push('col-lg-3 col-md-6 col-12');
          containerClasses.push('col-md-10 col-12');
          break;
      }
      return {
        keypoint: keypointClasses,
        container: containerClasses
      };
    },
    textColor() {
      return {
        "color": this.content.text_color
      }
    },
    descriptionClass() {
      console.log(this.content.description_class);
      return this.content.description_class;
    },
  }
};
</script>

<template>
<div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" class="themed-item" :class="[contentType, paddingY]" :style="theme" list-keypoints-component>
  <div :class="skinSet.container" class="container py-5" >
    <div class="row justify-content-center m-2">
      <div 
        :class="[skinSet.keypoint, content.align_items ? `align-items-center align-items-md-${content.align_items}` : null, content.text_align ? `text-lg-${content.text_align}` : null]"
        class="w-100 my-3 px-3 d-flex flex-column align-items-center text-center" 
        v-for="item in content.items"
        :key="`list-${item.$id}`"
        v-if="item">
        <div class="row keypoint-image mb-4" :class="{'icon-image': !content.avatar_icon}">
          <div class="ar-1by1 h-100 bg-cover-center w-100 avatar" :class="{'rounded-circle': content.avatar_icon}" :style="{ 'background-image': `url(${getImage(item)})`}" ></div>           
        </div>
        <div class="row justify-content-center" :class="{'mt-5': content.avatar_icon}">
          <div class="col-md-12 h5">{{getTitle(item)}}</div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-12 small" v-html="getDescription(item)"></div>
        </div>
        <div class="row justify-content-center mb-2" v-if="getButton(item)">
          <a
            :class="getButtonClass(item)"
            class="btn shadow-none m-2 text-uppercase"
            :href="getLink(item)"
            :target="getUrlTarget(item)">{{getUrlTitle(item)}}</a>
        </div>
      </div>          
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
[list-keypoints-component] {

  .icon-image {
    width: 62px !important; 
    height: 62px !important;
  }

  .keypoint-image{
    width: 112px;
    height: 112px;
  }

  .link-primary .fa-arrow-right{
    color: var(--primary);
  }

  .btn{
    padding: 10px;
    font-size: 70%;
    font-weight: 400;
  }
}
</style>
