<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  computed: {
    tickets() {
      const tickets = globalState.pageModel.user.reward_name_to_counter?.ticket ?? 0;
      return  `${tickets>0 ? '+' : ''}${tickets}`
    }
  },
  methods: {
    logo(index) {
      if (this.content.logos && this.content.logos.length > 0) {
        return this.content.logos[index];
      }
    }
  },
};
</script>

<template>
  <nav id="main-navbar" class="navbar navbar-expand-lg navbar-dark bg-dark sticky-top align-items-center p-0" vue-header-component>
    <a v-if="logo(0)" class="navbar-brand d-none d-md-flex align-items-center ml-3" :href="logo(0).url.url" :target="getTarget(logo(0).url)" :rel="getRelNoFollow(logo(0).url)">
      <img :src="logo(0).image.url" class="h-75">
    </a>
    <a v-if="logo(1)" class="navbar-brand center d-flex align-items-center" :href="logo(1).url.url" :target="getTarget(logo(1).url)" :rel="getRelNoFollow(logo(1).url)">
      <img :src="logo(1).image.url" class="h-100">
    </a>
    <div class="ml-auto h-100 d-flex align-items-center">
      <a v-if="logo(0)" class="navbar-brand d-flex d-md-none align-items-center ml-3" :href="logo(0).url.url" :target="getTarget(logo(0).url)" :rel="getRelNoFollow(logo(0).url)">
        <img :src="logo(0).image.url" class="h-75 logo0">
      </a>
      <a v-if="logo(2)" class="navbar-brand d-flex align-items-center mr-3" :href="logo(1).url.url" :target="getTarget(logo(2).url)" :rel="getRelNoFollow(logo(2).url)">
        <span class="d-none d-md-block">In partnership con</span> <img :src="logo(2).image.url" class="h-75 ml-md-3 ml-2 logo1">
      </a>
      <div v-if="content.instant_win_button" class="d-flex align-items-center ml-md-2 instant-win-box px-2 px-md-3 h-100">
        <span class="d-none d-md-block">{{tickets}}<i class="fa-light fa-ticket-simple fa-rotate-by ml-2" style="--fa-rotate-angle: 125deg;"></i></span>
        <a 
          class="btn btn-transparent btn-outline-dark d-flex align-items-center text-uppercase text-instant-win px-2 py-3 px-md-4 ml-md-3" 
          :href="content.instant_win_button.url">
          {{content.instant_win_button.title}}
        </a>
      </div>
    </div>
  </nav>
</template>

<style lang="scss">
[vue-header-component] {
  background-color: $black;
  height: $header-height;
  position: sticky;

  .navbar-brand {
    margin: 0;
    height: 100%;
    padding: 1rem 0;

    span {
      font-size: 0.75rem;
    }

    &.center {
      position: absolute;
      padding: 0.65rem 0;
      left: 50%;
      transform: translateX(-50%);
    }

    @include media-breakpoint-down(sm) {
      left: 1rem !important;
      transform: none !important;
      height: 90% !important;
    }
  }

  @include media-breakpoint-down(sm) {
    .logo0, .logo1 {
      height: 25px !important;
    }
  }

  .instant-win-box {
    background-color: $white;
  }

}

</style>

