<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { generateToken } from 'src/modules/recaptcha_v3_module.js';
import FormFeedback from 'form-feedback';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  components: {FormFeedback},
  data: function() {
    return {
      paddingYDefault: 5,
      form: {
        loading: false, error: null, success: null, code: "", message: null
      }
    };
  },
  mounted() {
    if (this.isUserRegistered()) {
      this.generateCode();
    }
  },
  methods: {
    isUserRegistered: Fandom.isUserRegistered,
    canShare() {
      return navigator.share != undefined;
    },
    share() {
      if (this.canShare()) {
        navigator.share({
          title: this.content.inviter.title,
          text: this.shareText,
          url: this.shareUrl
        }).then(() => {
          // nothing to do
        }).catch((error) => {
          this.form.success = false;
          this.form.message = this.ft("invite_friend.errors.share");
        });
      } else {
        this.copyToClipboard();
      }
    },
    copyToClipboard() {
      navigator.clipboard.writeText(`${this.shareText} ${this.shareUrl}`).then(() => {
        this.form.success = true;
        this.form.message = this.ft("invite_friend.copy");
      }).catch(err => {
        this.form.success = false;
        this.form.message = this.ft("invite_friend.errors.copy");
      });
    },
    generateCode() {
      if (!this.form.loading) {
        this.form.loading = true;
        this.form.success = null;
        this.form.errors = null;

        Fandom.ajax({
          method: "GET",
          url: this.applyContextToUrl("/invitation/code"),
          success: (data) => {          
            if (data.success) {
              this.form.code = data.code;
            }
          }
        }, 
        () => {
          this.form.loading = false;
        });
      }
    },
    validateCode(event) {
      if (!this.form.loading) {
        this.form.loading = true;
        this.form.success = null;
        this.form.errors = null;

        const execute = (token) => {
          Fandom.ajax({
            method: event.target.method.toUpperCase(),
            data: { code: this.form.code, recaptcha_enterprise_token: token },
            url: event.target.action,
            success: (data) => {
              if (data.success) {
                this.form.success = true
                this.form.message = data.message;
              }
            },
            error: (jqXHR, textStatus, errorThrown) => {
              this.form.error = jqXHR.responseJSON.error;
            },
          }, 
          () => {
            this.form.code = ""
            this.form.loading = false;
          });
        }
        globalState.pageModel.aux.recaptcha_enterprise ? generateToken(execute, "submit") : execute();
      }
    }
  },
  computed: {
    shareText() {
      return this.content.inviter.share_text || this.content.inviter.title;
    },
    shareUrl() {
      const baseUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
      return `${baseUrl}${this.applyContextToUrl("/invitation/validate")}?code=${this.form.code}`;
    }
  }
}
</script>

<template>
  <div v-easyadmin="easyadminId" class="text-center themed-item" :style="theme" :class="[contentType, paddingY]" v-if="isContentVisibleToUser()" interaction-invite-friend-component>
    <div v-if="isUserRegistered()" class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 mb-3">
          <component :is="content.inviter.title_tag || 'h2'" v-if="content.inviter.title">{{content.inviter.title}}</component>
          <component :is="content.inviter.description_tag || 'div'" v-html="content.inviter.description" v-if="content.inviter.description"></component>
        </div>
        <div class="col-md-10">
          <form-feedback :success="form.success" :success-message="form.message" :error-message="form.error"></form-feedback>
          <label class="h3 font-weight-normal cursor-pointer mb-0 code bg-light" @click="copyToClipboard">{{form.code}}</label>
          <p class="cursor-pointer mt-2 text-underline mb-0" @click="share">{{ft("invite_friend.share")}}</p>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 mb-3">
          <component :is="content.invited.title_tag || 'h2'" v-if="content.invited.title">{{content.invited.title}}</component>
          <component :is="content.invited.description_tag || 'div'" v-html="content.invited.description" v-if="content.invited.description"></component>
        </div>
        <div class="col-md-10">
          <form-feedback :success="form.success" :success-message="form.message" :error-message="form.error"></form-feedback>
          <form v-on:submit.prevent="validateCode($event)" :action="applyContextToUrl('/invitation/validate')" class="mx-auto" method="GET">            
            <div class="form-group">
              <input v-model="form.code" class="form-control" type="text" :placeholder="ft('invite_friend.code')">
            </div>
            <button type="submit" class="btn btn-primary" :disabled="form.loading || !form.code.length">{{ft("globals.form.send")}}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[interaction-invite-friend-component] {
  .code {
    letter-spacing: 10px;
    padding: 1.25rem 1.75rem 1rem 1.75rem;
  }

  form {
    width: 20rem;
    max-width: 100%;
  }

  .text-underline {
    text-decoration: underline;
  }
}

</style>
