<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import Flickity from "flickity";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data: function () {
    return { 
      slider: null
    };
  },
  mounted() {
    Vue.nextTick(() => {
      this.mountCarousel();
    });
  },
  methods: {
    mountCarousel() {
      const centeredTileSkins = ["1"];
      const slideConfig =  {
        draggable: "> 1",
        prevNextButtons: true,
        contain: true,
        pageDots: true,
        cellAlign: (centeredTileSkins.indexOf(this.skin) >= 0 ? 'center' : 'left'),
        wrapAround: (centeredTileSkins.indexOf(this.skin) >= 0 ? true : false),
        groupCells: true,
        arrowShape: "M47.89,1,.78,48.11a2.68,2.68,0,0,0,0,3.78L47.89,99a2.67,2.67,0,0,0,3.79,0l1.58-1.57a2.67,2.67,0,0,0,0-3.79L13.42,53.79h83.9A2.67,2.67,0,0,0,100,51.12V48.88a2.67,2.67,0,0,0-2.68-2.67H13.42L53.26,6.36a2.67,2.67,0,0,0,0-3.79L51.68,1A2.67,2.67,0,0,0,47.89,1Z"
      }

      this.slider = new Flickity(this.$refs["flickity-carousel"], slideConfig);

      this.slider.on("dragStart", () => {
        $(this.$refs["flickity-carousel"]).find('.flickity-carousel-item').css('pointer-events', 'none');
      });

      this.slider.on("dragEnd", () => {
        $(this.$refs["flickity-carousel"]).find('.flickity-carousel-item').css('pointer-events', 'all');
      });
    }
  },
  computed: {
    skin() {
      return this.content.skin || "1";
    },
    skinSet() {
      const classes = [`stripe-carousel__skin-${this.skin}`];
      return classes;
    },
    contentStyle() {
      return {
        "background-color": this.content.background_color
      };
    },
    containerClass() {
      return ["3"].indexOf(this.skin) >= 0 ? "container px-sm-0" : "container-fluid";
    },
    aspectRatioClass() {
      let aspectRatio = this.content.aspect_ratio;
      if (aspectRatio) {
        // legacy management
        return aspectRatio.startsWith("ar-") ? aspectRatio : `ar-${aspectRatio}`
      } else {
        return "ar-16by9";
      }
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="[contentType, paddingY]" :style="[contentStyle, theme]" :id="anchorId" class="themed-item" grid-carousel-component>
    <div class="w-100 position-relative" :class="containerClass">
      <div class="flickity-carousel" ref="flickity-carousel" :class="[skinSet]">
        <div class="flickity-carousel-item" v-for="(child, index) in content.children" :key="`carousel-slide-${index}`" :child_name="child">
          <div class="w-100" :class="aspectRatioClass">
            <component 
              :is="getContent(child).layout" 
              :content="getContent(child)"
            ></component>
          </div>
        </div>
        <div class="flickity-carousel-item" v-if="!!content.view_more_url">
          <div class="w-100" :class="aspectRatioClass">
            <a
              class="view-more-link position-absolute-center d-flex flex-column justify-content-center align-items-center"
              :href="content.view_more_url.url"
              :target="getTarget(content.view_more_url)"
            >
              <i class="fal fa-5x fa-plus-circle"></i>
              <div class="mt-4">{{content.view_more_url.title}}</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[grid-carousel-component] {
  position: relative;

  .flickity-page-dots {
    display: none;
  }

  .flickity-viewport:focus, .flickity-carousel:focus {
    outline: none !important;
  }

  .flickity-button.flickity-prev-next-button {
    z-index: 2;
  }

  .flickity-button.flickity-prev-next-button:disabled {
    display: none;
  }

  .flickity-button-icon {
    transform: scale(.65);
  }

  .flickity-carousel-item {
    padding-left: 1rem;
    padding-right: 0rem;

    > div {
      position: relative;
    }
  }

  .stripe-carousel__skin-1 .flickity-carousel-item {
    width: 75%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .stripe-carousel__skin-2 .flickity-carousel-item {
    width: 20%;
  }

  .stripe-carousel__skin-3 {
    .flickity-prev-next-button.next {
      right: -4rem;
    }

    .flickity-prev-next-button.previous {
      left: -4rem;
    }

    .flickity-carousel-item {
      width: calc(100% / 3);
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  /* side shadows */
  // .flickity-viewport::after,
  // .flickity-viewport::before {
  //   z-index: 1;
  //   opacity: 0;
  //   content: '';
  //   height: 100%;
  //   width: 5.25rem;
  //   position: absolute;
  //   pointer-events: none;
  //   transition: opacity .5s;
  //   background: transparent;
  // }

  // .flickity-carousel .flickity-viewport::after,
  // .flickity-carousel:not(.hide-left-shadow) .flickity-viewport::before {
  //   opacity: 1;
  // }

  // .flickity-carousel .flickity-viewport::before {
  //   background: linear-gradient(to left, rgba(0, 0, 0, 0), $dark);
  // }

  // .flickity-carousel .flickity-viewport::after {
  //   right: 0;
  //   background: linear-gradient(to right, rgba(0, 0, 0, 0), $dark);
  // }
  /* end side shadows */

  @include media-breakpoint-down(lg) {

    .stripe-carousel__skin-2 .flickity-carousel-item {
      width: 25%;
    }

    .stripe-carousel__skin-3 .flickity-carousel-item {
      width: 40%;
    }
  }

  @include media-breakpoint-down(sm) {

    .flickity-viewport::after,
    .flickity-viewport::before {
      width: 2rem;
    }

    .stripe-carousel__skin-1 .flickity-carousel-item {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      width: 90%;
    }

    .flickity-button.flickity-prev-next-button {
      display: none;
    }

    .stripe-carousel__skin-2 .flickity-carousel-item {
      width: 65%;
    }

    .stripe-carousel__skin-3 .flickity-page-dots {
      display: block;
    }

    .stripe-carousel__skin-3 .flickity-carousel-item {
      width: 100%;
      padding-left: 1rem;
    }
  }
}
</style>
