<template>
  <div ref="modal" :data-backdrop="backdrop" page-live-event-alert-component v-easyadmin="easyadminId" :class="contentType" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" :class="{'modal-dialog-centered': centered}" role="document">
      <div class="modal-content rounded">
        <div class="modal-header border-bottom-0 pb-0 position-relative">
          <button @click="closeModal" type="button" aria-label="Close" class="close">
            <i class="close-icon fal fa-lg fa-times"></i>
          </button>
        </div>
        <div class="modal-body text-center text-dark bg-white p-4 mb-4">
          <h2 v-if="content.title" class="modal-title">{{ content.title }}</h2>
          <div class="font-weight-normal py-3" v-html="content.description" v-if="content.description"></div>
          <div class="w-100 d-flex justify-content-center align-items-center pt-2" v-if="hasButton">
            <a
              class="btn btn-dark"
              :href="buttonUrl"
              :target="getTarget(content.button)"
              @click="mayCloseModal(content.button.url)"
            >
              {{content.button.title}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    backdrop: {
      type: Boolean|String,
      default: true
    },
    centered: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: this.value
    }
  },
  mounted() {
    if (this.active) {
      $(this.$refs.modal).modal("show");
    }

    Fandom.onEvent("show-modal", id => {
      if (id === this.modalId) {
        $(this.$refs.modal).modal("show");
      }
    });
  },
  computed: {
    hasButton() {
      const button = this.content.button;
      return button?.url && button?.title;
    },
    buttonUrl() {
      const url = this.content.button?.url ?? false;
      return url == "$close" ? "javascript:void(0)" : url;
    }
  },
  methods: {
    closeModal() {
      $(this.$refs.modal).modal("hide");
      this.$emit('input', false);
    },
    mayCloseModal(url) {
      if (url === "$close") {
        this.closeModal()
      }
    }
  },
  watch: {
    value(newVal) {
      this.active = newVal;
      $(this.$refs.modal).modal("toggle");
    }
  }
};
</script>

<style lang="scss" scoped>
[page-live-event-alert-component] {
  z-index: 1090;

  .modal-content, .modal-body, .btn {
    border-radius: $player-border-radius !important;
  }

  .close-icon {
    font-size: 1.5rem;
  }
}
</style>
