<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  data: function () {
    return {
      spySidebar: [],
      offset: null
    };
  },
  mounted() {
    // A navbar is created based on the presence of the title in the component
    this.$children.forEach(item => {
      if (item.content.title) {
        this.spySidebar.push({
          anchorId: item.anchorId,
          title: item.content.title
        });
      }
    });

    Vue.nextTick(() => {
      this.offset = $("#main-navbar").outerHeight();
      $('body').scrollspy({ target: "#sidebar-nav", offset: this.offset });
    })
  },
  computed: {
    contentStyle() {
      return {
        '--main-navbar-height': `${this.offset}px`
      };
    }
  },
  methods: {
    anchorTo(item) {
      const position = $(`#${item.anchorId}`).offset().top - this.offset;
      $("html, body").animate({ scrollTop: position }, 300, "linear");
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" v-if="isContentVisibleToUser()" :style="contentStyle" page-with-sidebar-component>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-auto d-lg-block px-0 py-2 bg-light spy-sidebar sticky-top">
          <nav id="sidebar-nav" class="navbar navbar-expand-lg navbar-light">
            <button class="navbar-toggler" type="button" data-toggle="collapse" :data-target="`#${anchorId}-navbar`" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" :id="`${anchorId}-navbar`">
              <ul class="navbar-nav flex-column">
                <li v-for="item in spySidebar" class="nav-item">
                  <a class="nav-link" :href="`#${item.anchorId}`" @click.prevent="anchorTo(item)">{{item.title}}</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div class="col-12 col-md">
          <div v-for="stripe in content.stripes" :key="stripe.$id">
            <component :is="stripe.layout" :content="stripe" :opt-container-content="containerContent"></component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[page-with-sidebar-component] {

  .nav-link.active {
    color: $primary;
  }

  .spy-sidebar {
    top: var(--main-navbar-height);
  }

  @include media-breakpoint-up(md) {
    .spy-sidebar {
      width: 26.5rem;
      overflow-y: scroll;
      height: calc(100vh - var(--main-navbar-height));
    }
  }

} 
</style>