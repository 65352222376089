<template>
  <div :style="avatarStyle" class="avatar-container d-flex justify-content-center align-items-center" ai-avatar-content>
    <div v-if="!avatarLoaded" class="position-fixed">
      <i class="fa-duotone fa-spinner-third fa-spin fa-2xl"></i>
    </div>
    <canvas ref="avatarCanvas" class="canvas webgl"></canvas>
  </div>
</template>

<script>
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { VISEMES_MAP } from 'src/modules/ai/constants';
import anime from 'animejs/lib/anime.es.js';

export default {
  props: {
    value: {
      type: String,
      required: true
    },
    audioUrl: {
      type: String,
      required: false
    },
    visemes: {
      type: Array,
      required: false
    },
    emotion: {
      type: String,
      default: 'neutral',
      validator: (value) => {
        return ['neutral', 'happy', 'sad'].includes(value)
      }
    },
    background: {
      type: Object
    },
    environmentImage: {
      type: Object
    },
    forceResize: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      audioBufferNode: null,
      avatar: null,
      avatarMainMesh: null,
      avatarService: null,
      scene: new THREE.Scene(),
      camera: null,
      renderer: null,
      sizes: {},
      currentVisemeIndex: 0,
      clock: new THREE.Clock(),
      mixer: null,
      animations: {},
      morphTargets: {},
      morphTargetDictionary: {},
      avatarLoaded: false,
      animationFadeTime: 2,
      prevAnimationName: null
    }
  },
  mounted() {
    this.initModel();
    this.initSizes();
    this.initCamera();
    this.initEnvironment();
    this.initRender();
    this.tick();
  },
  methods:{
    initModel() {
      const gltfLoader = new GLTFLoader();
      gltfLoader.load(this.value, (gltf) => {
        this.initAvatar(gltf);

        if (this.avatarService) {
          this.initMorphTargets();
          this.initAnimations(gltf.animations);

          this.playEyesBlink();
          this.controlAnimations(this.audioUrl);

          this.scene.add(this.avatar);
          this.avatarLoaded = true;
          this.$emit("ready");
        } else {
          alert("Avatar service not recognised!");
        }
      });
    },
    initAvatar(gltf) {
      this.avatar = gltf.scene;

      const readyPlayerMe = this.avatar.getObjectByName('Wolf3D_Avatar');
      const avatarSDK = this.avatar.getObjectByName('AvatarRoot');

      this.avatarMainMesh = readyPlayerMe || avatarSDK;
      this.avatarService = readyPlayerMe ? 'ready-player-me' : avatarSDK ? 'avatar-sdk' : undefined;
    },
    initMorphTargets() {
      switch (this.avatarService) {
        case 'ready-player-me':
          this.morphTargets.main = this.avatarMainMesh.morphTargetInfluences;
          this.morphTargetDictionary.main = this.avatarMainMesh.morphTargetDictionary;

          break;
        case 'avatar-sdk':
          this.morphTargets.main = this.avatarMainMesh.children[2].morphTargetInfluences;
          this.morphTargetDictionary.main = this.avatarMainMesh.children[2].morphTargetDictionary;

          this.morphTargets.eyelashes = this.avatarMainMesh.children[1].morphTargetInfluences;
          this.morphTargetDictionary.eyelashes = this.avatarMainMesh.children[1].morphTargetDictionary;

          this.morphTargets.teeth = this.avatarMainMesh.children[7].morphTargetInfluences;
          this.morphTargetDictionary.teeth = this.avatarMainMesh.children[7].morphTargetDictionary;

          break;
      }
    },
    initAnimations(animations) {
      this.mixer = new THREE.AnimationMixer(this.avatar);
      animations.forEach(animation => {
        this.animations[animation.name] = this.mixer.clipAction(animation);
      });
    },
    initSizes() {
      this.sizes = {
        width: window.innerWidth,
        height: window.innerHeight
      }
      window.addEventListener('resize', this.handleResize);
    },
    initCamera() {
      this.camera = new THREE.PerspectiveCamera(45, this.sizes.width / this.sizes.height, 0.1, 100);
      this.camera.position.x = 0;
      this.camera.position.y = 1.85;
      this.camera.position.z = 1.75;
      this.camera.rotation.x = - Math.PI * 0.1;
      this.scene.add(this.camera);
    },
    initEnvironment() {
      const rgbeLoader = new RGBELoader();
      rgbeLoader.load(this.environmentImage.url, (environmentMap) => {
        environmentMap.mapping = THREE.EquirectangularReflectionMapping;
        this.scene.environment = environmentMap;
      })
    },
    initRender() {
      this.renderer = new THREE.WebGLRenderer({
        canvas: this.$refs.avatarCanvas,
        antialias: true,
        alpha: true
      });
      this.updateRendererSize();
    },
    updateRendererSize() {
      this.renderer.setSize(this.sizes.width, this.sizes.height);
      this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    },
    handleResize() {
      this.sizes.width = window.innerWidth;
      this.sizes.height = window.innerHeight;

      this.camera.aspect = this.sizes.width / this.sizes.height;
      this.camera.updateProjectionMatrix();

      this.updateRendererSize();
    },
    playAudio() {
      const delayTime = this.animationFadeTime / 2;
      setTimeout(() => {
        if (this.audioUrl && this.visemes.length > 0) {
          let request = new XMLHttpRequest();
          request.open('GET', this.audioUrl, true);
          request.responseType = 'arraybuffer';
          request.onload = () => {
            const audioContext = new AudioContext();
            audioContext.decodeAudioData(request.response, (buffer) => {
              this.currentVisemeIndex = -1;
              this.audioBufferNode = audioContext.createBufferSource();
              this.audioBufferNode.buffer = buffer;
              this.audioBufferNode.connect(audioContext.destination);
              this.audioBufferNode.start();
              this.audioBufferNode.onended = () => this.$emit('ended');
              this.$emit("playing", buffer.duration);
            });
          }
          request.send();
        }
      }, delayTime*1000);
    },
    tick() { 
      this.checkViseme();
      this.updateMixer();
      this.renderer.render(this.scene, this.camera);
      window.requestAnimationFrame(this.tick);
    },
    checkViseme() {
      if (this.audioBufferNode?.context.currentTime > 0 && this.audioBufferNode.context.currentTime < this.audioBufferNode.buffer.duration) {
        const audioCurrentTime = this.audioBufferNode.context.currentTime * 1000;

        if (this.currentVisemeIndex + 1 < this.visemes.length) {
          const nextVisemeTime = this.visemes[this.currentVisemeIndex + 1].time;

          if (audioCurrentTime >= nextVisemeTime - 350) {
            this.currentVisemeIndex++;
            this.playViseme();
          }
        }
      }
    },
    setMorph(target, dictionaryKey, value) {
      this.morphTargets[target][this.morphTargetDictionary[target][dictionaryKey]] = value;
    },
    playViseme() {
      if (this.avatar) {
        const currentViseme = this.visemes[this.currentVisemeIndex];
        const viseme = VISEMES_MAP[this.avatarService][currentViseme.value];
        const animeViseme = { value: 0 };

        anime({
          targets: animeViseme,
          value: [0, 0.5],
          duration: 700,
          update: () => {
            this.setMorph('main', viseme, animeViseme.value);

            if (this.avatarService == 'avatar-sdk') {
              this.setMorph('teeth', viseme, animeViseme.value * 1.2);
            }
          }
        });

        if (this.currentVisemeIndex > 0) {
          const previousViseme = VISEMES_MAP[this.avatarService][this.visemes[this.currentVisemeIndex - 1].value];
          const animePreviousViseme = { value: 0.5 };

          anime({
            targets: animePreviousViseme,
            value: [0.5, 0],
            duration: 700,
            update: () => {
              this.setMorph('main', previousViseme, animePreviousViseme.value);

              if (this.avatarService == 'avatar-sdk') {
                this.setMorph('teeth', previousViseme, animePreviousViseme.value * 1.2);
              }
            }
          });
        }
      }
    },
    updateMixer() {
      if (this.mixer) {
        this.mixer.update(this.clock.getDelta());
      }
    },
    playAnimation(name) {
      const animation = this.animations[name];

      if (animation) {
        const prevAnimation = this.prevAnimationName ? this.animations[this.prevAnimationName] : null;

        if (this.prevAnimationName === name) return;

        if (prevAnimation) {
          animation.reset();
          prevAnimation.crossFadeTo(animation, this.animationFadeTime).play();
        } else {
          animation.play();
        }

        this.prevAnimationName = name;
      }
    },
    playEyesBlink() {
      const animeEyesBlink = { value: 0 };
      anime({
        targets: animeEyesBlink,
        value: [0, 1, 0],
        easing: 'easeInOutCubic',
        duration: 400,
        delay: 3500,
        loop: true,
        update: () => {
          this.setMorph('main', 'eyeBlinkRight', animeEyesBlink.value);
          this.setMorph('main', 'eyeBlinkLeft', animeEyesBlink.value);

          if (this.avatarService == 'avatar-sdk') {
            this.setMorph('eyelashes', 'eyeBlinkRight', animeEyesBlink.value);
            this.setMorph('eyelashes', 'eyeBlinkLeft', animeEyesBlink.value);
          }
        }
      });
    },
    playEmotion(emotion) {
      const animeEmotion = { value: 0 };

      anime.timeline({
        targets: animeEmotion,
        easing: 'easeInOutCubic',
        update: () => {
          if (emotion === 'happy') {
            const targets = ['main'];
            if (this.avatarService == 'avatar-sdk') { targets.push('eyelashes') };

            targets.forEach(target => {
              this.setMorph(target, 'cheekSquintLeft', animeEmotion.value * 0.2);
              this.setMorph(target, 'cheekSquintRight', animeEmotion.value * 0.2);
              this.setMorph(target, 'eyeSquintLeft', animeEmotion.value * 0.2);
              this.setMorph(target, 'eyeSquintRight', animeEmotion.value * 0.2);
              this.setMorph(target, 'mouthDimpleLeft', animeEmotion.value * 0.2);
              this.setMorph(target, 'mouthDimpleLeft', animeEmotion.value * 0.2);
              this.setMorph(target, 'mouthSmileLeft', animeEmotion.value * 0.6);
              this.setMorph(target, 'mouthSmileRight', animeEmotion.value * 0.6);
            })
          }
        }
      })
      .add({
        value: [0, 1],
        duration: 600
      })
      .add({
        value: [1, 0],
        duration: 800
      }, '+=1500');
    },
    controlAnimations(audio) {
      audio ? this.playTalk() : this.playIdle();
    },
    playTalk() {
      this.playAudio();
      this.playAnimation('talk');
    },
    playIdle() {
      this.playAnimation('idle');
      this.audioBufferNode?.stop();
    }
  },
  computed: {
    hasBgVideo() {
      return this.background?.url && !Fandom.isImage(this.background.url);
    },
    avatarStyle() {
      if (!this.hasBgVideo) {
        return {
          'background-image': Fandom.getBackgroundUrl(this.background.url)
        }
      }
      return {}
    }
  },
  watch: {
    audioUrl(audio) {
      if (this.avatarLoaded) {
        this.controlAnimations(audio);
      }
    },
    emotion(emotion) {
      this.playEmotion(emotion);
    },
    forceResize(newVal) {
      if (newVal) {
        this.handleResize();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-container {
  background-size: cover;
}
</style>
