<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js'
import { sendFandomAnalyticsEvent } from "src/modules/fandom_analytics_module.js";
import postscribe from "postscribe"

export default {
  mixins: [fandomBasicMixin],
  mounted() {
    Vue.nextTick(() => {
      const snippet = this.content.snippet;
      $(this.$el).one('inview', function(event, isInView) {
        if (isInView) {
          postscribe(`#juicer-snippet`, snippet);
        }
      });
    });
  },
  methods: {
    sendAnalyticsData() {
      sendFandomAnalyticsEvent("Interaction", "Social Post Click", "Not defined");
    }
  },
  computed: {
  }
}
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" class="py-4" stripe-juicer-component>
    <div class="w-100">
      <div class="container position-relative">
        <div id="juicer-snippet"></div>
        <div class="row">
          <div class="col-12">
            <div class="h2 text-center">{{ content.title }}</div>
            <div class="juicer-content" v-html="content.description" @click="sendAnalyticsData()"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

[stripe-juicer-component] {
  background-color: $black;
  color: $white;

  @include media-breakpoint-up(sm) {
    [stripe-header] {
      padding-bottom: 1rem !important;
      padding-top: 0rem !important;
    }
  }
}
</style>