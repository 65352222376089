<script>
import { sendFandomAnalyticsEvent }  from "src/modules/fandom_analytics_module.js";
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import TextSection from "text-section";
import GenericForm from "generic-form";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  components: {
    TextSection,
    GenericForm
  },
  methods: {
    formSubmitDone (data) {
      if (data.success) {
        sendFandomAnalyticsEvent("Simple Form", "Submit", `content_${this.contentName}_interaction_${this.content.interaction.id}`);
        this.$emit('simple-form-sent');
      }
    },
    getContentStyle () {
      var styleObject = {};
      if (Fandom.exists(this.content.background_color)) {
        styleObject["background-color"] = this.content.background_color;
      }
      if (Fandom.exists(this.content.background_image)) {
        styleObject["background-image"] = this.getContentResponsiveImageURL("background_image");
      }
      if (Fandom.exists(this.content.text_color)) {
        styleObject["color"] = this.content.text_color;
      }
      return styleObject;
    }
  }
};
</script>

<template>
  <div :id="content.name" v-easyadmin="easyadminId" :class="contentType" class="bg-cover-center themed-item" v-if="isContentVisibleToUser()" :style="[theme, getContentStyle()]" simple-form-component>
    <div class="container">
      <div class="row justify-content-center align-items-center py-5">
        <div class="col-sm-12 col-md-8 col-sm-6">
          <text-section :content="content" :opt-container-content="optContainerContent"/>
          <generic-form :content="content" :opt-container-content="containerContent" @done="formSubmitDone"></generic-form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
</style>
