<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { isInteractionDone } from 'src/modules/interactions_module.js';
import { get } from 'lodash';
import PageLiveEventQa from 'page-live-event-qa';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  components: { PageLiveEventQa },
  props: {
    activeChapter: {
      type: Object
    },
    unitIndex: {
      type: Number
    },
    value: {
      type: String,
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      innerValue: "",
      presentTabs: {
        'chat': !!this.content.comments,
        'poll': !!this.content.live_interactions,
        'chapters': !!this.content.chapters && this.content.chapters.length > 0
      }
    }
  },
  mounted() {
    if (!!this.content.active_tab) {
      this.innerValue = this.content.active_tab;
    } else if (this.value && this.presentTabs[this.value]) {
      this.innerValue = this.value;
    } else {
      this.innerValue = this.presentTabs.chat ? 'chat' : this.presentTabs.poll ? 'poll' : 'chapters'
    }
  },
  watch: {
    'content.live_interactions'(newVal) {
      if ((!newVal || newVal.length == 0) && this.content.comments) {
        $("#list-chat-list").click();
      }
    },
    value(newVal, oldVal) {
      if (newVal && (newVal !== oldVal && newVal !== this.innerValue)) {
        const tab = newVal === 'live_interactions' ? 'poll' 
          : newVal === 'comments' ? 'chat'
          : newVal === 'chapters' ? 'chapters' : ''
        this.innerValue = tab;
      }
    }
  },
  methods: {
    get: get,
    isInteractionDone: isInteractionDone,
    handleSelectedTab(tabName) {
      this.innerValue = tabName;
      this.$emit('input', tabName);
    },
    loadChapter(chapter, index) {
      if (chapter.$id != this.activeChapter.$id) {
        this.$emit('load-chapter', { chapter: chapter, unitIndex: index });
      }
    },
    isChapterActive(chapter) {
      return  !!this.activeChapter ? this.activeChapter.$id == chapter.$id : false;
    },
    isListActive(subchapters) {
      return subchapters.findIndex((subc) => this.isChapterActive(subc)) > -1;
    },
    bannerHandler(btn) {
      const url = btn.url;
      if (url.startsWith("#")) {
        $(url).click();
      } else {
        window.location.href = url;
      }
    }
  },
};
</script>

<template>
  <div class="container-lg d-flex flex-column live-event-sidebar" :class="{'live-event-sidebar--fullscreen': fullscreen}">
    <div class="row">
      <div class="list-group col-12 flex-row px-0" id="list-tab" role="tablist">
        <a
          @click="handleSelectedTab('chat')"
          role="tab"
          href="#list-chat"
          data-toggle="list"
          id="list-chat-list"
          aria-controls="chat"
          v-if="content.comments"
          :class="{'active': innerValue==='chat'}"
          class="list-group-item list-group-item-action rounded-0 border-left-0 border-right-0 border-top-0 border-bottom flex-shrink-1 m-0"
        >{{content.comments.title || ft("page_live_event.qa.title")}}</a>
        <a
          @click="handleSelectedTab('poll')"
          role="tab"
          href="#list-poll"
          data-toggle="list"
          id="list-poll-list"
          aria-controls="poll"
          :class="{'active': innerValue==='poll'}"
          v-if="content.live_interactions"
          class="list-group-item list-group-item-action rounded-0 border-left-0 border-right-0 border-top-0 border-bottom flex-shrink-1 m-0"
        >{{ft("page_live_event.interactions.title")}}</a>
        <a
          @click="handleSelectedTab('chapters')"
          role="tab"
          href="#list-chapters"
          data-toggle="list"
          id="list-chapters-list"
          aria-controls="chapters"
          :class="{'active': innerValue==='chapters'}"
          v-if="content.chapters && content.chapters.length > 0"
          class="list-group-item list-group-item-action rounded-0 border-left-0 border-right-0 border-top-0 border-bottom flex-shrink-1 m-0"
        >{{ft("page_live_event.chapters.title")}}</a>
        <div class="w-100 h-100 d-flex align-items-center justify-content-end border-bottom">
          <a :class="fullscreen ? 'd-none' : 'd-flex'" class="align-items-center deco-none d-lg-none" data-toggle="collapse" href="#nav-tabContent" role="button" aria-expanded="false" aria-controls="nav-tabContent">
            <i class="fal fa-lg fa-times px-4 close-icon"></i>
            <i class="far fa-lg fa-angle-down px-4 open-icon"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="row interactions-area flex-fill">
      <div class="tab-content col-12 px-0 collapse show" id="nav-tabContent">
        <div  
          class="tab-pane fade px-3"
          id="list-chat"
          :class="{'show active': innerValue==='chat'}"
          role="tabpanel"
          aria-labelledby="list-chat-list"
          v-if="content.comments"
        >
          <page-live-event-qa
            :content="content.comments"
            :opt-container-content="containerContent"
          ></page-live-event-qa>
          <div class="banner p-4 w-100 text-center" v-if="content.banner">
            <div v-if="content.banner.title" class="mb-4">{{content.banner.title}}</div>
            <button
              class="btn w-100"
              :class="this.content.banner.button_class ? this.content.banner.button_class : this.containerContent.theme === 'dark' ? 'btn-light' : 'btn-dark'"
              v-if="content.banner.button"
              @click="bannerHandler(content.banner.button)"
            >{{content.banner.button.title}}</button>
          </div>
        </div>
        <div
          class="tab-pane fade px-3"
          id="list-poll"
          role="tabpanel"
          :class="{'show active': innerValue==='poll'}"
          aria-labelledby="list-poll-list"
          v-if="content.live_interactions"
        >
          <div class="row">
            <div class="col-12 py-2">
              <transition-group
                enter-active-class="animated fadeIn"
                leave-active-class="animated fadeOut"
                tag="div"
              >
                <div v-for="liveInteraction in content.live_interactions.slice().reverse()" :key="`interaction-${liveInteraction.interaction.id}`">
                  <component
                    class="my-3"
                    :is="liveInteraction.layout"
                    :content="liveInteraction"
                    :id="`anchor-${containerContent.id}-${liveInteraction.interaction.id}`"
                    :opt-container-content="containerContent"
                  ></component>
                </div>
              </transition-group>
            </div>
          </div>
        </div>
        <div  
          class="tab-pane fade"
          id="list-chapters"
          role="tabpanel"
          :class="{'show active': innerValue==='chapters'}"
          aria-labelledby="list-chapters-list"
          v-if="content.chapters && content.chapters.length > 0"
        >
          <div v-for="(chapter, index) in content.chapters" :key="`chapter-${index}`" class="border-bottom px-3">
            <h3 class="mb-0">
              <button
                :aria-expanded="(index == unitIndex)"
                data-toggle="collapse"
                :aria-controls="`collapse${chapter.$id}`"
                :data-target="`#course-chapter-${chapter.$id}`"
                :class="{'collapsed': index != unitIndex}"
                class="btn btn-link w-100 d-flex justify-content-between px-0 py-3 rounded-0 align-items-center"
              >
                <div class="text-left pr-2" :class="{'font-weight-normal': isListActive(chapter.chapters)}">{{chapter.title}}</div>
                <small><i class="far fa-chevron-up"></i></small>
              </button>
            </h3>
            <div :id="`course-chapter-${chapter.$id}`" class="collapse" :class="{'show': (index == unitIndex)}" data-parent="#list-chapters">
              <ul v-if="chapter.chapters" class="list-unstyled">
                <li v-for="(subchapter, subindex) in chapter.chapters" class="px-3 py-2 d-flex align-items-center" :key="`subchapter-${subindex}`" :class="{'font-weight-bold': isChapterActive(subchapter)}">
                  <i v-if="isChapterActive(subchapter)" class="far fa-play-circle"></i>
                  <i v-else-if="isInteractionDone(containerContent, get(subchapter, ['play', 'interaction'], false))" class="far fa-check-circle"></i>
                  <i v-else class="fal fa-circle"></i>
                  <a href="javascript: void(0)" class="ml-3 deco-none" @click="loadChapter(subchapter, index)">{{subchapter.title}}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$tab-header-height: 49px;

.live-event-sidebar {
  z-index: 1019;
  position: relative;
  color: var(--theme-color);
  border-bottom: 1px solid $gray-300;
  background-color: var(--theme-background);

  &--fullscreen {
    position: relative;
    height: var(--inner-height, 100vh);
    top: 0;

    .interactions-area {
      max-height: calc(var(--inner-height, 100vh) - #{$tab-header-height});
      overflow-y: scroll;
    }
  }

  @include media-breakpoint-up(lg) {
    &:not(.live-event-sidebar--fullscreen) {
      position: sticky;
      top: $header-height;
      height: calc(100vh - #{$header-height});
      border-bottom: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    &.live-event-sidebar--fullscreen {
      height: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    &.live-event-sidebar--fullscreen {
      ::v-deep [versus-widget-component] {
        .question-card {
          .question-text {
            font-size: 1rem;
          }

          .answer {
            font-size: .875rem;
            padding-bottom: .5rem !important;
            padding-top: .5rem !important;
          }
        }
      }
    }
  }
}

.list-group-item-action {
  width: auto !important;
  background-color: var(--theme-background);
  color: var(--theme-color);

  &.active {
    color: inherit;
    font-weight: 500;

    &:after {
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      position: absolute;
      border-bottom: 3px solid $primary !important;
    }
  }
}

[data-toggle="collapse"] {
  &:not(.collapsed) > .open-icon,
  &.collapsed > .close-icon {
    display: none;
  }
  
  &.collapsed i.fa-chevron-up {
    transform: rotate(180deg);
  }
}

.btn-link {
  color: inherit;
  text-decoration: none;
  box-shadow: none;
}

.interactions-area {
  max-height: 100%;
  
  @include media-breakpoint-up(lg) {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  @include ie {
    height: 100%;
  }
}

.banner {
  background-color: var(--theme-color);
  color: var(--theme-background);
}
</style>
