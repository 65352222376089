<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
      contentFiltering: [],
      filterSelected: ""
    }
  },
  mounted() {
    this.initFilters();
  },
  methods: {
    initFilters() {
      this.contentFiltering = [];
      this.content.items.forEach(filter => {
        Vue.set(this.contentFiltering, this.contentFiltering.length, []);
      });
      this.toggleFilter(this.content.default_filter,0)
    },
    toggleFilter(filter, topIndex) {
      this.filterSelected = Fandom.getContent(filter).title;
      this.contentFiltering[topIndex] = filter;
      Vue.nextTick(() => {
        Fandom.emit('grid-filter-apply', {
          contentFiltering: this.contentFiltering,
          handlerContents: (this.content.handler_contents || "")
        });
      });
    },
  }
};

</script>

<template>
  <div 
    v-if="isContentVisibleToUser()" 
    v-easyadmin="easyadminId" 
    :class="[contentType, paddingY]"
    grid-filter-component
  >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="d-flex mx-n1 justify-content-between align-items-center">
            <div class="h6 text-uppercase m-0" :style="{'color': content.text_color}">{{ content.title }}</div>
            <div class="dropdown" v-for="(item, topIndex) in content.items" :key="`f-${item.$id}`">
              <button class="btn btn-primary btn-filter" :class="content.button_class" type="button" :id="`f-${item.$id}`" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{filterSelected}}<i class="ml-3 mt-1 far fa-angle-down"></i>
              </button>
              <div class="dropdown-menu" :aria-labelledby="`f-${item.$id}`">
                <a class="dropdown-item" v-for="(filter, index) in item.filter_contents" href="javascript:void(0)" @click="toggleFilter(filter, topIndex)">{{getContent(filter).title}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </div>
</template>

<style lang="scss" scoped>
[grid-filter-component] {
}
</style>
