<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import Breadcrumb from 'breadcrumb';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  components: {
    Breadcrumb
  },
  data: function () { 
    return { };
  },
  computed: {
    rootStyle() {
      return {
        "background-image": this.getContentResponsiveImageURL("background_image"),
        "background-color": this.content.background_color
      };
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :style="rootStyle" :class="[contentType, content.background_class]" v-if="isContentVisibleToUser()" class="position-relative" page-blank-component>
    <breadcrumb v-if="content.breadcrumb"/>
    <component :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
  </div>
</template>

<style lang="scss">
[page-blank-component] {
  background-size: 100% auto;
}
</style>
