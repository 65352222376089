<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { videoBasicMixin, videoInteractiveMixin } from "src/modules/vimeo_module.js";
import { merge, get } from 'lodash';

export default {
  mixins: [fandomBasicMixin, videoBasicMixin, videoInteractiveMixin],
  data: function () {
    return {
      videoContent: this.getInitialVideoContent()
    };
  },
  watch: {
    videoPlayer: {
      handler(newValue) {
        this.$emit("video-player-handler", newValue);
      },
      deep: true
    },
    overvideoInteraction(newValue) {
      this.$emit("overvideo-interaction-handler", newValue);
    }
  },
  methods: {
    unsetOvervideoInteraction() {
      this.overvideoInteraction = null;
    },
    getInitialVideoContent() {
      return merge(this.content.play, {
        videoConfig: {
          autoplay: get(this.content, ['play', 'autoplay'], false),
          muted: get(this.content, ['play', 'autoplay'], false), // depend on autoplay
          texttrack: globalState.lang,
          quality: this.content.play.quality || "720p",
          controls: !(get(this.content, ["play", "interactive"], false))
        }
      });
    }
  }
};
</script> 

<template>
  <div embed-vimeo-component>
    <div class="w-100 h-100 wrapper" :id="videoContentId"></div>
  </div>
</template>

<style lang="scss" scoped>
.wrapper ::v-deep iframe {
  width: var(--video-width);
  height: var(--video-height);
}
</style>
