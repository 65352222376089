import { render, staticRenderFns } from "./grid-fixed.vue?vue&type=template&id=644112fc"
import script from "./grid-fixed.vue?vue&type=script&lang=js"
export * from "./grid-fixed.vue?vue&type=script&lang=js"
import style0 from "./grid-fixed.vue?vue&type=style&index=0&id=644112fc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports