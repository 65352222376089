<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
  methods: {
    isUserRegistered() {
      return Fandom.isUserRegistered();
    },
  },
};
</script>

<template>
  <div footer-component>
    <component v-if="content.stripes" :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="content"></component>
    <div class="container">
      <div class="row py-4 py-lg-5">
        <div class="col-12 col-lg-4 d-flex justify-content-center align-items-center logo-wrapper">
          <div class="logo py-4" v-for="(logo, index) in content.logos">
            <a :href="logo.url.url" :target="getTarget(logo.url)" :rel="getRelNoFollow(logo.url)">
              <img :src="logo.image.url">
            </a>
          </div>
        </div>
        <div class="col-12 col-lg-7 d-flex align-items-center py-4">
          <p class="text-white mb-0" v-html="content.description"></p>
        </div>
      </div>
    </div>
    <hr class="m-0 bg-light">
    <div class="container py-4">
      <div class="row justify-content-between align-items-center">
        <div class="col">
          <a
            class="navbar-link text-uppercase text-decoration-none text-light"
            v-for="(item, index) in content.navbar"
            :href="item.url.url"
            :target="getTarget(item.url)"
            >
            {{item.url.title}}
          </a>
          <a v-if="isUserRegistered()" class="navbar-link text-decoration-none text-uppercase text-light" :href="applyContextToUrl('/users/sign_out')">{{ft('globals.navbar.sign_out')}}</a>
        </div>
        <div class="col-auto" v-for="(social, index) in content.navbar_social">
          <a
            v-for="(icon, iconIndex) in social.items"
            :href="icon.url.url"
            class="social-icon text-decoration-none"
            :target="getTarget(icon.url)"
            >
            <i class="fab fa-2x text-light" :class="icon.icon"></i>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="mb-0 text-white recaptcha">
            This site is protected by reCAPTCHA and the Google 
            <a href="https://policies.google.com/privacy" target="_blank" class="text-white">Privacy Policy</a> and 
            <a href="https://policies.google.com/terms" target="_blank" class="text-white">Terms of Service</a> apply.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[footer-component] {
  background-color: $black;
  $social-icon-size: 1rem;

  .recaptcha {
    font-size: 0.75rem;
  }

  .logo-wrapper {
    .logo {
      & img {
        max-height: 100%;
        width: 10rem;

        @include media-breakpoint-down(md) {
          width: 7rem;
        }
      }

      @include media-breakpoint-down(md) {
        &:first-child {
          padding-right: 3rem;
        }
      }
    }
    @include media-breakpoint-down(md) {
      flex-direction: row;
    }
    @include media-breakpoint-up(lg) {
      flex-direction: column;
    }
  }

  .navbar-link {
    &:not(:last-child) {

      &::after {
        content: '-';
        color: $light !important;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
      }
    }
  }
}
</style>
