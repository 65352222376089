<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { interactionMixin } from "src/modules/interactions_module.js";
import { liveEventsModalMixin } from 'src/modules/live_events_v2_module.js';
import { isInteractionDone } from "src/modules/interactions_module.js";
import Flickity from "flickity";
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin, liveEventsModalMixin, interactionMixin],
  data() {
    return {
      carousel: null,
      activeContent: null,
      activeContentAlreadyDoneTimeout: null
    };
  },
  mounted() {
    if (this.gameDone) {
      Vue.set(this, 'preventModalOpen', true);
    }
    if (this.content.active && !this.gameDone) {
      Vue.nextTick(() => {
        $(this.$el).modal("show");
      });
    }
    $(this.$el).on('shown.bs.modal', () => {
      this.carousel = new Flickity(this.$refs["interaction-carousel"], {
        prevNextButtons: false,
        draggable: false,
        adaptiveHeight: false,
        accessibility: false,
        on: {
          change: (index) => {
            this.activeContent = this.interactions[index - 1];
          }
        }
      });
    });
    $(this.$el).on('hidden.bs.modal', () => {
      if (!this.gameDone) {
        this.$emit('close');
      }
    });
  },
  watch: {
    gameDone(newVal) {
      if (newVal) {
        Vue.set(this, 'preventModalOpen', true);
      } else {
        Vue.set(this, 'preventModalOpen', false);
      }
    },
    // The feedback phase is managed by the interaction module
    activeContent(newValue) {
      clearTimeout(this.activeContentAlreadyDoneTimeout);
      if (this.activeContentAlreadyDoneTimeout) {
        this.activeContentAlreadyDoneTimeout = null;
      }

      if (Fandom.exists(newValue) && this.isInteractionDone(this.containerContent, newValue.interaction) && this.carousel.selectedIndex <= (this.interactions.length)) {
        this.activeContentAlreadyDoneTimeout = setTimeout(() => {
          this.carousel.next();
        }, 3000);
      }
    }
  },
  computed: {
    gameDone() {
      return this.content.trivia_carousel.reduce((result, gameStep) => {
        return result && isInteractionDone(this.containerContent, gameStep.interaction)
      }, true);
    },
    interactions() {
      return this.content.trivia_carousel || this.content.interactions;
    },
    showNavigation() {
      return Fandom.exists(this.carousel);
    },
    isThisInteractionFeedbackPhase() {
      return this.activeContent && this.isInteractionFeedbackPhase(this.containerContent, this.activeContent.interaction);
    },
    lockNextSlide() {
      let lockNextSlide = false;
      if (this.activeContent && this.activeContent.interaction) {
        if (!this.isInteractionDone(this.containerContent, this.activeContent.interaction)) {
          lockNextSlide = true;
        }
      } else {
        lockNextSlide = false
      }
      return lockNextSlide;
    },
    correctAnswers() {
      let count = 0;
      this.interactions.forEach(trivia => {
        if (this.isInteractionDone(this.containerContent, trivia.interaction)) {
          const userInteraction = this.getUserInteraction(this.containerContent, trivia.interaction);
          const userInteractionAnswerId = Fandom.exists(userInteraction.answer_id) ? userInteraction.answer_id : parseInt(userInteraction.data.answer_id);
          if (this.isAnswerCorrect(this.containerContent, trivia.interaction, userInteractionAnswerId)) {
            count++;
          }
        }
      });
      return count;
    }
  },
  methods: {
    closeModal() {
      $(this.$el).modal("hide");
    },
    openModal() {
      $(this.$el).modal("show");
    },
    deactivateModal() {
      this.$emit('clearnotice', {id: this.content.id || this.content.$id });
      $(this.$el).modal("hide");
    },
    getInteractionFeedbackText() {
      if (this.isVersus(this.activeContent)) {
        return this.ft("page_live_event.versus.feedback_title");
      }
      if (this.isAnswerCorrect(this.containerContent, this.activeContent.interaction, this.getUserInteractionAnswerId(this.containerContent, this.activeContent.interaction))) {
        return this.ft("globals.trivia_interaction.feedback_success_text");
      } else {
        return this.ft("globals.trivia_interaction.feedback_wrong_text");
      }
    },
    getFeedbackClass() {
      if (this.isVersus(this.activeContent)) {
        return 'bg-dark';
      }
      if (this.isAnswerCorrect(this.containerContent, this.activeContent.interaction, this.getUserInteractionAnswerId(this.containerContent, this.activeContent.interaction))) {
        return 'bg-success';
      } else {
        return 'bg-danger';
      }
    },
    updateTriviaInteractionCustomized(answer, triviaContent) {
      if (!this.isInteractionDone(this.containerContent, triviaContent.interaction)) {
        this.activeContent = null;
        this.updateTriviaInteraction(this.containerContent, this.content, triviaContent.interaction, answer.$id, () => {}, () => {
          this.activeContent = triviaContent;
        });
      }
    },
    getTriviaAnswerClasses(answer, content) {
      const defaultBtnClass = "btn-outline-dark";
      if (this.isInteractionDone(this.containerContent, content.interaction)) {
        if (this.isAnswerCorrect(this.containerContent, content.interaction, answer.$id)) {
          return `btn-success`;
        } else {
          const userInteraction = this.getUserInteraction(this.containerContent, content.interaction);
          const userInteractionAnswerId = Fandom.exists(userInteraction.answer_id) ? userInteraction.answer_id : parseInt(userInteraction.data.answer_id);
          return userInteractionAnswerId == answer.$id ? `btn-danger` : defaultBtnClass;
        }
      } else {
        return defaultBtnClass;
      }
    },
    getVersusAnswerClasses(answer, content) {
      if (this.isInteractionDone(this.containerContent, content.interaction)) {
        const userInteraction = this.getUserInteraction(this.containerContent, content.interaction);
        return userInteraction.data.answer_id == answer.$id ? "btn-outline-primary" : "btn-outline-dark";
      } else {
        return 'btn-outline-dark';
      }
    },
    getVersusAnswerPercentage(answer, content) {
      const contentUserInfo = this.getContentUserInfo(this.containerContent);
      if((this.isInteractionDone(this.containerContent, content.interaction)) || get(contentUserInfo, ["id_to_interaction", content.interaction.id, "updated_at"], null)) {
        let percentage = get(this.getContentUserInfo(this.containerContent).id_to_interaction[content.interaction.id],
        ["id_to_answer", answer.$id, "percentage"], 0);
        return percentage.toFixed(0);
      }
    },
    versusProgressWidthStyle(percentage){
      if (this.containerContent && this.containerContent.reverse_progress_bars){
        return {['width']: `${parseInt(100 - percentage)}%`};
      } else {
        return {['width']: `${parseInt(percentage)}%`};
      }
    },
    updateVersusInteractionCustomized(answer, content){
      if (!this.isInteractionDone(this.containerContent, content.interaction)) {
        this.activeContent = null;
        const contentUserInfo = this.getContentUserInfo(this.containerContent);
        if(!get(contentUserInfo, ["id_to_interaction", content.interaction.id, "updated_at"], null)){
          this.updateVersusInteraction(this.containerContent, this.content, content.interaction, answer.$id, () => {}, () => {
            this.activeContent = content;
          });
        }
      }
    },
    isVersus(content) {
      return get(content, ['interaction', 'type'], false) == 'Versus';
    },
    isUserAnswer(answerId, content) {
      return this.getUserInteractionAnswerId(this.containerContent, content.interaction) == answerId;
    }
  }
};
</script>

<template>
  <div class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" v-easyadmin="easyadminId" :class="contentType" trivia-game-modal-component>
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <i @click="closeModal()" class="fal fa-lg fa-times p-4 cursor-pointer"></i>
        </div>
        <div class="modal-body">
          <div class="h-100 d-flex flex-column justify-content-between align-items-center position-relative">
            <div class="w-100 h-100 text-center">
              <div class="row mx-0 h-100">
                <div class="col-12 position-relative background-centered">
                  <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                    <div v-if="isThisInteractionFeedbackPhase" class="trivia-carousel__feedback my-3">
                      <div class="d-inline-flex align-items-center justify-content-center text-uppercase" :class="getFeedbackClass()">
                        <p class="feedback-text mb-0"><small>{{getInteractionFeedbackText()}}</small></p>
                        <div v-if="!isVersus(activeContent)">
                          <div class="feedback-icon ml-3" v-if="isAnswerCorrect(containerContent, activeContent.interaction, getUserInteractionAnswerId(containerContent, activeContent.interaction))">
                            <i class="fal fa-check"></i>
                          </div>
                          <div class="feedback-icon ml-3 mt-1" v-else>
                            <i class="fal fa-times"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </transition>
                  <div class="w-100 h-100 trivia-carousel" ref="interaction-carousel">
                    <div class="trivia-carousel-item w-100 h-100 d-flex justify-content-center align-items-center flex-column py-5 text-center">
                      <div class="col-12 col-lg-8 py-5 d-flex align-items-center justify-content-center">
                        <div class="text-center w-100" v-if="content.cover">
                          <h3 class="h2 mb-2">{{content.cover.title}}</h3>
                          <div v-html="content.cover.description"></div>
                          <button type="button" class="btn btn-dark my-4" @click="carousel.next()">{{content.cover.button_text}}</button>
                        </div>
                        <div v-else>missing required cover in cover record</div>
                      </div>
                    </div>
                    <div
                      v-for="(triviaContent, index) in interactions"
                      class="trivia-carousel-item w-100 h-100 d-flex flex-column align-items-center justify-content-center"
                      :key="`trivia-content-${index}`"
                    >
                      <div class="col-lg-8 text-center d-flex flex-column justify-content-center" v-if="!isVersus(triviaContent)">
                        <div v-if="showNavigation" class="w-100 trivia-carousel__progress">
                          <div class="text-uppercase mb-0 font-weight-bold nav-indicator">
                            {{ft('page_live_event.question')}} {{carousel.selectedIndex}} {{ft('page_live_event.of')}} {{interactions.length}}
                          </div>
                        </div>
                        <h3 class="h2 trivia-question py-4 mb-0">{{triviaContent.question}}</h3>
                        <div class="row">
                          <div v-for="(answer, answer_index) in triviaContent.answers" class="col-12 col-md-6 my-2 px-2" :key="`trivia-asnwer-${index}-${answer_index}`">
                            <button @click="updateTriviaInteractionCustomized(answer, triviaContent)" :class="[getTriviaAnswerClasses(answer, triviaContent)]" class="btn px-3" >
                              {{answer.text}}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-8 text-center" v-else versus-item>
                        <div v-if="showNavigation" class="w-100 trivia-carousel__progress">
                          <div class="text-uppercase mb-0 font-weight-bold nav-indicator">
                            {{ft('page_live_event.question')}} {{carousel.selectedIndex}} {{ft('page_live_event.of')}} {{interactions.length}}
                          </div>
                        </div>
                        <h3 class="h2 trivia-question py-4 mb-0">{{triviaContent.question}}</h3>
                        <div class="row">
                          <div class="col-12 col-md-6 my-2 px-2" v-for="(answer, answer_index) in triviaContent.answers" :key="`versus-${index}-${answer_index}`">
                            <button @click="updateVersusInteractionCustomized(answer, triviaContent)" :class="getVersusAnswerClasses(answer, triviaContent)" class="btn px-3 position-relative">
                              <div class="overflow-hidden versus-answer-background-container position-absolute-center" v-if="isInteractionDone(containerContent, triviaContent.interaction)">
                                <div class="versus-answer-background-progress">
                                  <div class="versus-answer-progress" :style="versusProgressWidthStyle(getVersusAnswerPercentage(answer, triviaContent))"></div>
                                </div>
                              </div>
                              {{answer.text}}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- last slide -->
                    <div class="trivia-carousel-item w-100 h-100 d-flex justify-content-center align-items-center flex-column py-5 text-center">
                      <div class="col-12 col-lg-8 py-5 d-flex align-items-center justify-content-center mh-100vh-header">
                        <div class="text-center">
                          <h3 class="h2">{{ft("page_live_event.waiting_game.result", { ca: correctAnswers, tot: interactions.filter(el => !isVersus(el)).length })}}</h3>
                          <a class="btn btn-dark my-4" href="javascript: void(0)" @click="deactivateModal">{{ft("globals.close")}}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[trivia-game-modal-component] {
  color: $black;

  .modal-header {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    z-index: 1;
  }

  .nav-indicator {
    font-size: .75rem;
  }

  .trivia-carousel-item {
    min-height: calc(75vw * 0.56);

    .btn {
      width: 21.25rem;
      max-width: 100%;
    }

    @include media-breakpoint-down(sm) {
      min-height: calc(100vh - #{$header-height-sm});
      .btn {
        width: 100%;
      }
    }
  }

  .flickity-page-dots {
    bottom: 24px;

    .dot {
      pointer-events: none;
    }
  }

  .trivia-carousel__feedback {
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    pointer-events: none;
    z-index: $zindex-sticky;

    > div {
      color: $white;
      padding: 0.5rem 2rem;
      border-radius: 25px;
    }
  }

  [versus-item] {
    .versus-answer-background-container {
      border-radius: $btn-border-radius;
      .versus-answer-background-progress {
        position: absolute;
        background-color: $gray-300;
        bottom: 0;
        left: 0;
        right: 0;

        .versus-answer-progress {
          height: 4px;
        }
      }
    }

    .btn-outline-primary {
      .versus-answer-progress { background-color: $primary }
    }

    .btn-outline-dark {
      .versus-answer-progress { background-color: $dark }
    }
  }
}
</style>