<script>
import { get, debounce } from "lodash";
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      gradientBackground: false,
      sidebar: false
    };
  },
  methods: {
    isItemActive(item) {
      // When url is a ContentLink extra field type the content name can be used as matching 
      // name when mathing name is missing. 
      if (item.url.content && !item.matching_name) {
        item.matching_name = item.url.content;
      }
      const re = new RegExp(`^(${item.matching_name})$`);
      return re.test(globalState.pageModel.main_content_name);
    },
    toggleSidebar() {
      if (this.sidebar) {
        clearAllBodyScrollLocks();
        this.sidebar = false;
      } else {
        disableBodyScroll(this.$refs["sidebar"]);
        this.sidebar = true;
      }
    },
    itemId(item) {
      return `main-navbar-item-${item.$id}`;
    }
  },
  computed: {
    isUserRegistered() {
      return Fandom.isUserRegistered();
    },
    username() {
      const user = globalState.pageModel.user;
      return `${user.first_name} ${user.last_name.charAt(0)}.`;
    },
    items() {
      return get(globalState, ["pageModel", "layout", "header", "items"], []); 
    },
    logoAlt() {
      return get(this.pageModel, ['seo', 'title'], '');
    }
  },
};
</script>

<template>
  <div class="fixed-top">
    <nav id="main-navbar" class="navbar navbar-expand-lg justify-content-between px-3 px-md-5" navbar-component>
      <div class="d-flex">
        <div v-if="items.length > 0" class="d-flex justify-content-center align-items-center text-white cursor-pointer" @click="toggleSidebar">
          <i class="fal fa-bars fa-2x"></i>
        </div>
        <a :class="{'mx-3 mx-md-5': items.length > 0}" class="navbar-brand d-flex align-items-center" :href="applyContextToUrl('/')">
          <img :src="content.logo.url" class="navbar-logo" :alt="logoAlt"/>
        </a>
      </div>
      <div class="d-flex justify-content-end">
        <div class="d-flex align-items-md-center flex-column flex-md-row align-items-end">
          <div class="nav-item px-2 position-relative">
            <a v-if="!isUserRegistered" class="nav-item-url font-weight-bold" :href="applyContextToUrl('/users/sign_in')">{{ft('globals.navbar.sign_in')}}</a>
            <div v-else class="text-white text-right">
              {{username}}<br>
              <a class="nav-item-url font-weight-bold" :href="applyContextToUrl('/users/sign_out')"><small>{{ft('globals.navbar.sign_out')}}</small></a>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <transition enter-active-class="animated faster fadeIn" leave-active-class="animated faster fadeOut">
      <div class="position-fixed" @click="toggleSidebar" v-if="sidebar" sidebar-layer></div>
    </transition>
    <transition enter-active-class="animated faster slideInLeft" leave-active-class="animated faster slideOutLeft">
      <div class="position-fixed bg-dark text-white border-right" ref="sidebar" v-if="sidebar" sidebar>
        <div class="close-icon position-absolute p-4 cursor-pointer">
          <i class="fal fa-times fa-2x" @click="toggleSidebar"></i>
        </div>

        <div class="d-flex flex-column h-100">
          <div class="flex-grow-1 sidebar-content">
            <img :src="content.logo.url" class="sidebar-logo" :alt="logoAlt"/>
            <div class="py-4">
              <div class="py-3 border-bottom nav-item" v-for="(item, index) in items" :key="`sidebar-item-${index}`">
                <a class="nav-item-url text-white font-weight-bold d-flex align-items-center justify-content-between" :href="item.url.url" :target="getTarget(item.url)">
                  <span>{{item.url.title}}</span>
                  <a class="nav-item-url text-white font-weight-bold collapsed collapse-toggle d-flex justify-content-end" data-toggle="collapse" role="button" aria-expanded="false" :aria-controls="itemId(item)" v-if="item.items" :href="`#${itemId(item)}`">
                    <i class="fal fa-chevron-up"></i>
                  </a>
                </a>
                <div class="collapse multi-collapse" :id="itemId(item)" v-if="item.items">
                  <ul class="list-unstyled mb-0 mt-2">
                    <li v-for="(subitem, subindex) in item.items" :key="'index-' + index + '-subindex-' + subindex">
                      <a class="nav-item-url text-white my-2 ml-2 text-normal" :href="subitem.url.url" :target="getTarget(subitem.url)" >
                        {{subitem.url.title}}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-black sidebar-footer py-4">
            <a v-if="!isUserRegistered" class="nav-item-url deco-none d-flex align-items-center text-white" href="/users/sign_in">
              <i class="fas fa-user mr-3 fa-lg"></i>
              {{ft('globals.navbar.sign_in')}}
              <i class="fal fa-chevron-right ml-3"></i>
            </a>
            <a v-else class="nav-item-url text-white" href="/users/sign_out">
              {{ft('globals.navbar.sign_out')}}
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>

.bg-gradients {
  background: $primary !important;
}

.navbar {
  background: $secondary !important;
}

[navbar-component] {
  min-height: $header-height-sm;
  transition: all $animation-time-fast linear;

  @include media-breakpoint-up(md) {
    background: transparent;
  }

  & > div:first-child,
  & > div:last-child {
    width: 12rem;

    @include media-breakpoint-down(md) {
      width: auto;
    }
  }

  .navbar-toggler {
    z-index: 1050;
    width: 3rem;
    text-align: center;
  }

  .navbar__support-content {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding-top: $header-height-sm;
  }

  .navbar-brand {
    min-height: calc(#{$header-height-sm} - 1rem);
  }

  .navbar-logo {
    height: 1.575rem;

    @include media-breakpoint-up(md) {
      height: 3.15rem;
    }
  }

  .sidebar-toggler, .close-icon {
    transition: color .25s;
    
    &:hover {
      color: $gray-600;
    }
  }
}

[navbar-component] .nav-item,
[sidebar] .nav-item {
  text-transform: uppercase;
  font-size: .9rem !important;
  letter-spacing: 1px;
  text-decoration: none !important;

  .nav-item-url {
    color: $gray-300;
    text-decoration: none;
    transition: color .25s;
    
    &.collapsed .fal {
      transform: rotate(180deg);
    }
  }

  &:hover,
  &:active,
  &:focus,
  &.active {
    .nav-item-url {
      color: $white;
    }
  }
}

[sidebar-layer] {
  z-index: $zindex-sticky + 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($dark, .5);
}

[sidebar] {
  z-index: $zindex-sticky + 1;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: .875rem;
  max-width: 17.5rem;
  padding-top: 1.5rem;
  overflow-y: auto;

  .user-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .sidebar-content,
  .sidebar-footer {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .close-icon {
    top: 0;
    right: 0;
  }

  .sidebar-logo {
    height: 1.575rem;
  }

  .text-normal {
    text-transform: none;
  }

  .collapse-toggle {
    min-width: 25%;
  }

  .border-bottom,
  &.border-right {
    border-color: rgba($gray-300, 0.15) !important;
  }
}
</style>
