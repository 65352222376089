<template>
  <div 
    v-easyadmin="easyadminId" 
    class="container"
    :class="[contentType, paddingY]" 
    :style="theme" 
    list-simple-component
  >
    <div class="row">
      <div class="col-12">
        <div 
          v-for="(item, index) in content.items"
          :key="`item-${index}`"
          class="card border-bottom"
        >
          <div class="card-body px-0">
            <div class="card-body__image">
              <img class="img-fluid" :src="item.image.url" :alt="item.image.alt">
            </div>
            <div class="card-body__content px-3 position-relative">
              <p class="card-title h5 mb-0">{{item.title}}</p>
              <p v-if="item.link" class="card-text mb-0">
                <a 
                  :href="item.link.url"
                  :target="item.link.new_tab && '_blank'"
                  class="text-uppercase"
                >
                  {{ item.link.title || ft('globals.view_more') }}
                  <i v-if="content.icon_url_class" class="pl-2" :class="content.icon_url_class"></i>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin]
};
</script>

<style lang="scss" scoped>
[list-simple-component] {
  .card {
    border: 0;

    &-body {
      display: flex;

      &__image {
        width: 7rem;
        height: auto;
        overflow: hidden;

        @include media-breakpoint-down(sm) {
          width: 4rem;
        }

        img {
          border-radius: .875rem;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        flex: 1;

        .card-title {
          font-style: italic;
          font-weight: 400;
          font-size: 1.5rem;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

          @include media-breakpoint-down(sm) {
            font-size: 1rem !important;
          }
        }

        .card-text {
          font-weight: 400;
          font-size: .75rem;
        }
      }

      &__actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}
</style>
