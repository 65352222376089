<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin],
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" class="p-2" page-live-event-notice-component>
    <div class="notice-container p-3 pr-sm-5 mx-auto">
      <a class="notice-close text-white" @click="$emit('clearnotice');" href="javascript: void(0)">
        <i class="fal fa-times"></i>
      </a>
      <div class="d-flex justify-content-between flex-column flex-sm-row">
        <div class="pr-4">
          <p class="mb-0 font-weight-bold" v-if="content.title">{{content.title}}</p>
          <p class="mb-0" v-if="content.description"><small v-html="content.description"></small></p>
        </div>
        <div class="d-flex justify-content-center justify-content-sm-end align-items-center mt-3 mt-sm-0">
          <a v-if="content.button" :href="content.button.url" class="btn btn-outline-light" :target="getTarget(content.button)">{{content.button.title}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[page-live-event-notice-component] {
  .notice-container {
    background-color: rgba($black, 0.75);
    color: $white !important;
    width: 75%;
    position: relative;

    .notice-close {
      position: absolute;
      right: 1.25rem;
    }

    &:after {
      content: "";
      background-color: $white;
      width: 5px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }

    a {
      white-space: nowrap;
    }
  }

  @include media-breakpoint-down(sm) {
    .notice-container {
      width: 100%;
    }
  }
}
</style>
