<template>
  <div class="face-recognition">
    <video ref="faceVideo" autoplay muted playsinline></video>
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import * as faceapi from 'face-api.js';
import { io } from "socket.io-client";

const CONTINUOUS_PROXIMITY = 5;

export default {
  props: {
    threshold: {
      type: Number|String,
      default: 0.4
    },
    inactiveTime: {
      type: Number,
      default: 7
    },
    socketUrl: {
      type: String,
    },
  },
  data() {
    return {
      faceFound: false,
      stream: null,
      facePresenceTimeoutId: null,
      timeoutId: null,
      socket: null,
      lastProximity: null,
      proximityCount: 0,
      socketReconnection: 5
    };
  },
  async mounted() {
    if (this.socketUrl) {
      this.initSocket();
      // load models in case of fallback
      this.loadModels();
    } else {
      await this.loadModels();
      this.initFaceApi();
    }
  },
  beforeDestroy() {
    this.stopFaceRecognition();
    this.resetOnPlay();
  },
  methods: {
    initSocket() {
      this.socket = io(this.socketUrl);
      this.socket.on("connect", () => console.log("socket connected"));
      this.socket.on("connect_error", this.handleSocketError);
      this.socket.on("sensor/distance", this.handleSensorMessage);
    },
    async loadModels() {
      const promises = [
        faceapi.loadSsdMobilenetv1Model(Fandom.expandUrl('$S3_ALIAS/commons/face-api/ssd_mobilenetv1_model-weights_manifest.json')),
        faceapi.loadFaceLandmarkModel(Fandom.expandUrl('$S3_ALIAS/commons/face-api/face_landmark_68_model-weights_manifest.json')),
        faceapi.loadFaceExpressionModel(Fandom.expandUrl('$S3_ALIAS/commons/face-api/face_expression_model-weights_manifest.json')),
        faceapi.nets.ageGenderNet.load(Fandom.expandUrl('$S3_ALIAS/commons/face-api/age_gender_model-weights_manifest.json'))
      ]

      await Promise.all(promises);
    },
    async initFaceApi() {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({ video: {} });
        this.$refs.faceVideo.srcObject = this.stream;
        this.$refs.faceVideo.onloadedmetadata = this.onPlay;
      } catch (err) {
        console.error(err);
      }
    },
    async onPlay() {
      const videoEl = this.$refs.faceVideo;
      const canvas = this.$refs.canvas;
      const faceOptions = new faceapi.SsdMobilenetv1Options({ minConfidence: this.threshold });

      const result = await faceapi
        .detectSingleFace(videoEl, faceOptions)
        .withFaceLandmarks()
        .withFaceExpressions()
        .withAgeAndGender();

      if (result) {
        // Draw results on canvas
        const dims = faceapi.matchDimensions(canvas, videoEl, true);
        const resizedDetections = faceapi.resizeResults(result, dims);
        faceapi.draw.drawDetections(canvas, resizedDetections);
        faceapi.draw.drawFaceExpressions(canvas, resizedDetections, 0.05);
        const { age, gender, genderProbability } = result;
        new faceapi.draw.DrawTextField(
          [
            `${faceapi.utils.round(age, 0)} years`,
            `${gender} (${faceapi.utils.round(genderProbability)})`
          ],
          result.detection.box.bottomRight
        ).draw(canvas)

        this.$emit('face-found', {age, gender, genderProbability})
        this.resetUserPresence();
      } else {
        canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
        if (!this.facePresenceTimeoutId) {
          this.facePresenceTimeoutId = setTimeout(() => {
            console.log(`no user found for ${this.inactiveTime}s --> back to idle state`);
            this.$emit('face-not-found')
          }, this.inactiveTime*1000);
        }
      }

      this.timeoutId = setTimeout(this.onPlay, 1000);
    },
    stopFaceRecognition() {
      this.resetUserPresence();
      this.stream?.getTracks().forEach(track => track.stop());
      this.stream = null;
    },
    resetOnPlay() {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    },
    resetUserPresence() {
      clearTimeout(this.facePresenceTimeoutId);
      this.facePresenceTimeoutId = null;
    },
    handleSensorMessage(data) {
      const proximity = data.proximity;
      if (proximity === this.lastProximity && proximity !== 'far') {
        this.proximityCount++;
        if (this.proximityCount === CONTINUOUS_PROXIMITY)
          this.$emit('face-found');
          console.log("toggleProximity", data);
      } else {
        this.proximityCount = 0;
        this.$emit('face-not-found');
      }

      this.lastProximity = proximity;
    },
    handleSocketError(error) {
      if (!this.socket.active) {
        console.log("socket not active", error.message);
        this.initFaceApi();
      } else {
        console.log("connect_error", error.message);
        if (this.socketReconnection-1 === 0) {
          this.socket.disconnect();
          this.initFaceApi();
        } else {
          this.socketReconnection--;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.face-recognition {
  video, canvas {
    width: 320px;
    max-width: 50vw;
  }

  canvas {
    position: absolute;
    left: 0;
  }
}
</style>