import { render, staticRenderFns } from "./tile-article.vue?vue&type=template&id=8c79477c&scoped=true"
import script from "./tile-article.vue?vue&type=script&lang=js"
export * from "./tile-article.vue?vue&type=script&lang=js"
import style0 from "./tile-article.vue?vue&type=style&index=0&id=8c79477c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c79477c",
  null
  
)

export default component.exports