<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import Intersect from 'vue-intersect';
import { get } from "lodash";
import Hls from "hls.js/dist/hls.min";

import TextSection from 'text-section';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  components: {
    Intersect,
    TextSection
  },
  mounted() {
    Vue.nextTick(() => {
      if (this.videoPopupPresent) {
        // Function prevents video opening by link in the text area
        this.$refs["text-section"].$el.querySelectorAll("a").forEach(function(element) {
          element.addEventListener('click', (e) => { e.stopPropagation(); });
        });
      }
    });

    if (this.backgroundVideoUrl) {
      this.initBgVideo();
    }
  },
  methods: {
    getContentResponsiveImageURL: Fandom.getContentResponsiveImageURL,
    initBgVideo() {
      const extension = this.backgroundVideoUrl.split('.').pop();
      if (extension === 'm3u8' && Hls.isSupported()) {
        const hls = new Hls({enableWorker: false});
        hls.loadSource(this.backgroundVideoUrl);
        hls.attachMedia(this.video);
      }
    },
    toggleVideo(status) {
      if (this.backgroundVideoUrl) {
        if (status) {
          this.video.muted = true;
          this.video.play();
        } else {
          this.video.pause();
        }
      }
    },
    mayToggleVideoPopup() {
      if (this.videoPopupPresent) {
        Vue.set(globalState, "videoPopupContent", { content: this.content, containerContent: this.containerContent });
      }
    }
  },
  computed: {
    videoPopupPresent() {
      return Fandom.exists(this.content.play) || Fandom.exists(this.content.embed_media);
    },
    columnClasses() {
      const size = get(this, ["content", "width"], 8);
      return `col-md-${size}`;
    },
    verticalAlign() {
      let align = '';
      switch (get(this, ["content", "vertical_align"], 'center')) {
        case 'bottom':
          align = 'end';
          break;
        case 'top':
          align = 'start';
          break;
        case 'center':
        default:
          align = 'center';
          break;
      }

      return `align-items-${align}`;
    },
    horizontalAlign() {
      let align = '';
      switch (get(this, ["content", "horizontal_align"], 'center')) {
        case 'left':
          align = 'start';
          break;
        case 'center':
          align = 'center';
          break;
        case 'right':
        default:
          align = 'end';
          break;
      }
      return `justify-content-${align}`;
    },
    video() {
      return $(this.$refs.video)[0];
    },
    backgroundVideoUrl() {
      const videoDesktop = get(this, "content.background_video.url", false);
      return get(globalState, "viewport.xs", false) ? get(this, "content.background_video_mobile.url", videoDesktop) : videoDesktop;
    },
    backgroundGradient() {
      if(typeof this.content.gradient == "boolean") {
        return {}
      } else {
        return {
          "background": this.content.gradient
        };
      }
    }
  }
};
</script>

<template>
  <div
    v-easyadmin="easyadminId" 
    :class="[contentType, paddingY, {'cursor-pointer': videoPopupPresent}]" 
    v-if="isContentVisibleToUser()" 
    :style="theme"
    :id="anchorId"
    class="themed-item"
    heading-media-component
  >
    <div :style="{'background-image': getContentResponsiveImageURL('background_image')}" class="bg-cover-center" @click="mayToggleVideoPopup()">
      <intersect v-if="backgroundVideoUrl" @enter="toggleVideo(true)" @leave="toggleVideo(false)">
        <div class="position-absolute-center">
          <div v-if="content.gradient" class="position-absolute-center gradient" :style="backgroundGradient"></div>
          <video loop muted webkit-playsinline playsinline autoplay ref="video" class="w-100 h-100">
            <source :src="expandUrl(backgroundVideoUrl)" type="video/mp4">
          </video>
        </div>
      </intersect>
      <div v-if="content.gradient && !backgroundVideoUrl" class="position-absolute-center gradient" :style="backgroundGradient"></div>
      <div :class="content.propagate_container ? 'container-fluid' : 'container'">
        <div class="row" :class="horizontalAlign">
          <div
            class="d-flex justify-content-center content-container"
            :class="[columnClasses, verticalAlign, {'mh-100vh-header': !content.height}]"
            :style="[{'min-height': content.height}]"
            >
            <text-section class="w-100" :content="content" :opt-container-content="containerContent" ref="text-section"></text-section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[heading-media-component] {
  position: relative;

  video {
    object-fit: cover;
  }

  .gradient {
    background: rgba(0,0,0,0.25);
  }
}
</style>
