<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import Flickity from "flickity";

export default {
  mixins: [fandomBasicMixin],
  data() {
    return {
      currentSlide: null
    };
  },
  methods: {
    scrollToBottom: Fandom.scrollToBottom,
    getContentURL: Fandom.getContentURL,
    getContentURLByContentName(contentName) {
      return Fandom.getContentURL(Fandom.getContent(contentName));
    },
  },
  mounted() {
    if (Fandom.exists(this.content.children)) {
      Vue.nextTick(() => {
        const childrenLength = this.content.children.length;
        const slider = new Flickity(this.$refs["flickity-carousel"], {
          draggable: ">1",
          prevNextButtons: (childrenLength > 1),
          wrapAround: true,
          pageDots: (childrenLength > 1),
          on: {
            ready: () => {
              setTimeout(() => {
                this.$refs[`heading-${this.content.children[0]}`][0].toggleVideo(true);
              }, 100);
            },
          }
        });
        this.currentSlide = slider.selectedElement;
      });
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" heading-caruosel-component>
    <div class="flickity-carousel" ref="flickity-carousel">
      <div 
          class="flickity-carousel-item w-100" 
          v-for="(item, index) in content.children" 
          :key="`item-${index}`">
        <a :href="getContentURL(getContent(item))" class="deco-none">
          <heading-media 
            :ref="`heading-${item}`" 
            :content="{...getContent(item), ...{height: content.height}}"
            :opt-container-content="containerContent"
            :class="{'mh-100vh-header': !content.height}" 
            :style="{'min-height': content.height}">
          </heading-media>
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[heading-caruosel-component] {
  position: relative;

  .flickity-carousel {
    position: relative;
    color: $white;
    background-color: $black;
  }

  .flickity-page-dots {
    bottom: .5rem;
    .dot {
      background-color: $white;
      border: 1px solid $white;
    }
  }

  @include media-breakpoint-down(xs) {
    .flickity-prev-next-button,
    .flickity-prev-next-button {
      display: none;
    }

    .flickity-page-dots {
      bottom: 1rem;
    }
  }
}
</style>

