<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { IFRAME_SOURCE, LIBRARY_SOURCE, ACTIONS } from 'src/modules/player/constants';

export default {
mixins: [fandomBasicMixin],
  data() {
    return {
      contentLoaded: false,
    };
  },
  mounted() {
    Fandom.onEvent('embed.post-message', (message) => {
      parent.postMessage({
        source: IFRAME_SOURCE,
        ...message
      }, '*');
    });

    window.addEventListener("message", (event) => {
      const message = event.data;
      if (message.source !== LIBRARY_SOURCE) {
        return;
      }

      this[message.action](message.payload);
    });
  },
  methods: {
    initializeIframe() {
      Fandom.getContents(this.content.name, {}, (data) => {
        const embeddedCN = this.content.name;
        if (data.name_to_content[embeddedCN]) {
          Vue.set(this.globalState.pageModel.name_to_content, embeddedCN, data.name_to_content[embeddedCN]);
          
          this.contentLoaded = true;
          parent.postMessage({
            source: IFRAME_SOURCE,
            action: ACTIONS.PLAYER.READY
          }, '*');
        }
      }, false);
    },
    setUser(data) {
      Fandom.post({
        url: '/api/v5/embedded/user_sign_in', 
        data, 
        success: (response) => {
          if (response.success) {
            sessionStorage.setItem('session-jwt', response.user.jwt);
            this.initializeIframe();
          }
        }
      })
    }
  },
};
</script>

<template>
  <div class="position-relative w-100" v-if="contentLoaded">
    <component 
      :is="content.layout"
      :content="content"
      class="position-absolute-center">
    </component>
  </div>
</template>
  