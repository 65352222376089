<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [fandomBasicMixin],
  methods: {
    emit: Fandom.emit
  }
};
</script>

<template>
  <div @click="emit('modal-content-required', content)">
    <tile :content="content" :opt-container-content="containerContent" tile-popup-component></tile>
  </div>

</template>

<style lang="scss">
</style>
