<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";
import ShareIcons from "share-icons";

export default {
  mixins: [fandomBasicMixin],
  components: { ShareIcons },
  methods: {
    getButtonClass: Fandom.getButtonClass,
    getPrimaryButtonClass: Fandom.getPrimaryButtonClass,
    mayRedirectTo(item, event) {
      if (item.url === "$video") {
        Vue.set(globalState, "videoPopupContent", { content: this.content, containerContent: this.containerContent });
        return false;
      } else if (item.url.startsWith("#")) {
          let offset = $("#main-navbar").outerHeight();
          if (this.content.sticky) {
            offset += $(this.$el).outerHeight();
          }

          const position = $(item.url).offset().top - offset;
          $("html, body").animate({ scrollTop: position }, 0, "linear");

         event.preventDefault();
         return false;
      } else {
        return true;
      }
    },
    getButtonUrl(button) {
      return button.url === "$video" ? "javascript:void(0)" : button.url;
    }
  },
  computed: {
    iconWidth() {
      return {
        width: get(this.content, "icon_width", "6rem")
      }
    },
    textAlign() {
      // Allowed values: left, right and center.
      return (this.content.text_align) ? `text-${this.content.text_align}` : null;
    },
    hasShareInteraction() {
      return get(this, "content.share.interaction.type", false) === "Share";
    },
    category() {
      return Fandom.exists(this.content.category) ? this.content.category : this.content.miniformat_title;
    },
    buttonsRequired() {
      return this.content.button || this.content.buttons;
    },
    mainClasses() {
      return [
        this.textAlign
      ];
    }
  }
};
</script>

<template>
  <div :class="mainClasses" text-section-component>
    <img v-if="content.icon" class="icon my-4" :alt="content.icon.alt" :src="content.icon.url" :style="iconWidth">
    <p v-if="category" class="category" :class="content.category_class" :style="{'color': content.text_color}">{{category}}</p>
    <component v-if="content.title" :class="content.title_class" :is="content.title_tag || 'h2'" :style="{'color': content.text_color}" title>
      {{content.title}}
    </component>
    <component v-if="content.subtitle" :is="content.subtitle_tag || 'h3'" :class="content.subtitle_class" :style="{'color': `${content.text_color}`}" subtitle>{{content.subtitle}}</component>
    <component :is="content.description_tag || 'div'" :class="content.description_class" v-if="content.description" :style="{'color': `${content.text_color}`}" v-html="content.description" description></component>
    <div v-if="buttonsRequired" class="align-btn-group my-4">
      <a 
        v-if="content.button" 
        class="btn m-2 my-2" 
        :class="getPrimaryButtonClass(content.button_class, content.theme)" 
        :target="getTarget(content.button)" 
        :href="getButtonUrl(content.button)"
        @click="mayRedirectTo(content.button, $event)"
      >
        {{content.button.title}}
      </a>
      <template v-if="content.buttons">
        <a 
          class="btn m-2" 
          v-for="(buttonRecord, index) in content.buttons" 
          :key="index" 
          :class="getPrimaryButtonClass(buttonRecord.button_class, content.theme)" 
          :target="getTarget(buttonRecord.button)" 
          :href="getButtonUrl(buttonRecord.button)"
          @click="mayRedirectTo(buttonRecord.button, $event)"
        >
          {{buttonRecord.button.title}}
        </a>
      </template>
    </div>
    <share-icons 
      class="align-btn-group my-4 m-0" 
      v-if="hasShareInteraction" 
      :content="content" 
      :container-content="containerContent" 
    ></share-icons>
  </div>
</template>

<style lang="scss" scoped>
[text-section-component] {

  .align-btn-group {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .icon {
    max-width: 100%;
  }
}
</style>
