<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  props: {
    tileLayout: String,
    items: Array,
    optAppendContents: Function
  },
  data() {
    return {
      getContentsParams: {
        limit: (this.content.limit || 8),
        content_filtering: []
      }
    }
  },  
  mounted() {
    Fandom.onEvent("grid-filter-apply", (data) => {
      if (data.handlerContents.indexOf(this.content.name) > -1) {
        this.getContentsParams.content_filtering = data.contentFiltering;
        Fandom.getContents(this.content.name, this.getContentsParams, () => {}, false);
      }
    });
  },
  methods: {
    appendContents(event) {
      if (this.optAppendContents) {
        this.optAppendContents();
      } else {
        Fandom.appendContents(this.content.name, null, null, this.getContentsParams, event);
      }
    },
  },
  computed: {
    paginationSEOUrl() {
      const page = parseInt(this.content.page) || 1
      return window.location.pathname + `?page_content_name=${this.content.name}&page=${page + 1}`; 
    },
    aspectRatioClass() {
      return this.content.aspect_ratio || 'ar-1by1';
    },
    children() {
      return this.items || (this.content.children || []).map(c => this.getContent(c));
    },
    horizontalAlign() {
      let align = '';
      switch (get(this, ["content", "horizontal_align"], 'left')) {
        case 'left':
          align = 'start';
          break;
        case 'center':
          align = 'center';
          break;
        case 'right':
        default:
          align = 'end';
          break;
      }
      return `justify-content-${align}`;
    },
    skinSet() {
      const classes = [];

      if (this.content.column_count) {
        classes.push(`row-cols-1 row-cols-md-2 row-cols-lg-${get(this.content, 'column_count', 3)}`);
      } else {
        switch(parseInt(this.content.skin)) {
          case 1:
            classes.push("row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1");
            break;
          case 2:
            classes.push("row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-cols-1");
            break;
          case 3:
            classes.push("row-cols-lg-3 row-cols-md-2 row-cols-1");
            break;
          case 4:
            classes.push("row-cols-lg-6 row-cols-md-3 row-cols-2");
            break;
          case 5:
            classes.push("row-cols-lg-2 row-cols-md-2 row-cols-1");
            break;
          default:
            // nothing to do
        }
      }

      return classes;
    },
  },
};

</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="[contentType, paddingY]" :style="theme" :id="anchorId" grid-fixed-component>
    <div class="container">
      <div v-if="children && children.length == 0" class="row">
        <div class="col-12"><p>{{ft("globals.no_content")}}</p></div>
      </div>
      <div :class="[skinSet, horizontalAlign]" class="row" v-else>
        <div v-for="(child, index) in children" class="col px-custom py-custom" :key="`child-${index}`">
          <div class="w-100 position-relative" :class="aspectRatioClass">
            <slot :child="child">
              <component :is="child.layout" :content="child" class="d-block w-100"></component>
            </slot>
          </div>
        </div>
      </div>
      <div class="row my-3"  v-if="(content.enable_load_more == null || content.enable_load_more) && content.has_more_children">
        <div class="col-12 d-flex justify-content-center">
          <a class="btn" :href="paginationSEOUrl" :class="themeButtonClass" @click.prevent="appendContents($event)">{{ft("globals.load_more")}}</a>
        </div>
      </div>
      <div class="row my-3" v-if="(content.enable_load_more != null || !content.enable_load_more) && content.view_more_url">
        <div class="col-12 d-flex justify-content-center">
          <a :href="content.view_more_url.url" :target="getTarget(content.view_more_url)" class="btn" :class="themeButtonClass">{{content.view_more_url.title}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[grid-fixed-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);
  
  .px-custom {
    padding-left: $fixed-grid-gap;
    padding-right: $fixed-grid-gap;
  }

  .py-custom {
    padding-top: $fixed-grid-gap;
    padding-bottom: $fixed-grid-gap;
  }
}
</style>