<script>
import { get } from "lodash";
import { fandomTranslate } from "src/modules/common_module";

export default {
  props: {
    cookieName: {
      type: String,
      default: "fandom_cookies_accepted"
    }
  },
  data() {
    return {
      cookiesAccepted: false
    };
  },
  computed: {
    cookieText() {
      const defaultText = fandomTranslate("globals.cookie.text");
      return get(globalState, ["pageModel", "layout", "content", "cookie_text"], defaultText);
    },
    areCookiesAccepted() {
      return this.cookiesAccepted || document.cookie.includes(this.cookieName);
    }
  },
  methods: {
    ft: fandomTranslate,
    setCookiesAccepted() {
      document.cookie = `${this.cookieName}=true; path=/;`;
      this.cookiesAccepted = true;
    }
  }
};
</script>

<template>
  <div class="row w-100 mx-0 pt-4 pb-3 pt-sm-5 pb-sm-5 justify-content-center position-fixed cookie-banner" v-if="!areCookiesAccepted">
    <div class="col-12 col-sm-10 row justify-content-between align-items-center">
      <div class="col-12 col-md-7">
        <p class="mb-0 banner-text" v-html="cookieText"></p>
      </div>
      <div class="col-12 col-md-5 d-flex justify-content-center mt-3 mt-md-0">
        <button class="btn btn-outline-dark px-5" @click="setCookiesAccepted">
          {{ft("globals.cookie.accept")}}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cookie-banner {
  background-color: rgba($white, .9);
  z-index: 1090;
  bottom: 0%;
}

.banner-text {
  @include font-size(.75rem);
}
</style>
