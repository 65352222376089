<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { Player, PlayerBanners } from 'player';

export default {
  mixins: [fandomBasicMixin],
  components: { Player, PlayerBanners },
  data: function() {
    return {
      currentTime: 0
    }
  },
  mounted() {
    disableBodyScroll(); 
  },
  beforeDestroy() {
    clearAllBodyScrollLocks();
  },
  methods: {
    handleToggleFullscreen(fullscreen) {
      !fullscreen.active && this.handleClose();
    },
    handleClose() {
      Vue.delete(globalState, "videoPopupContent");
    },
    handleBannerClick(banner) {
      if (banner.url) {
        window.location.href = banner.url.url;
      }
    }
  },
  computed: {
    banners() {
      return this.content.play.banners?.filter(b => b.show_banner && (b?.seconds ?? 0) <= this.currentTime)
    },
    playContent() {
      const data = {config: {}, ...this.content.play};
      if (typeof data.config.fullscreen === 'undefined') {
        data.config.fullscreen = true;
      }

      return data;
    }
  }
};
</script>

<template>
  <div class="bg-dark" video-popup-component> 
    <div class="video-popup__embed-container">
      <player
        ref="player"
        class="fandom-player"
        :content="playContent"
        :disable-left-sidebar="true"
        :opt-container-content="containerContent"
        fullscreenTargetClass="video-popup__embed-container"
        @player:time="(time)=>currentTime=time"
        @player:toggleFullscreen="handleToggleFullscreen"
        @close="handleClose"
      >
      <template #sub-header>
        <player-banners 
          v-if="banners"
          :products="banners"
          :banner-audio="content.play.banner_audio"
          @banner-clicked="handleBannerClick"
        ></player-banners>
      </template>
      </player>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[video-popup-component] {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1091;
  height: 100vh;

  .video-popup__embed-container {
    height: 100vh;
    position: relative;
  }
}
</style>
