<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import Intersect from 'vue-intersect';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  components: {
    Intersect
  },
  data() {
    return {
      contents: [],
      paging: {},
      paddingYDefault: 5,
      loading: false
    };
  },
  computed: {
    cursorAfter() {
      return this.paging?.cursors?.after ?? "";
    }
  },
  methods: {
    fetchItems() {
      this.loading = true;
      Fandom.ajax({
        method: "GET",
        url: Fandom.applyContextToUrl("/api/v5/meta_business/instagram_contents"),
        data: {
          after: this.cursorAfter
        },
        success: (response) => {
          if (response.success) {
            this.paging = response.paging;
            this.contents = this.contents.concat(response.data);
          } else {
            console.log("%cerror: " + response.error, "color: red");
          }
        }
      }, () => {
        this.loading = false;
      })
    }
  }
};

</script>

<template>
  <intersect @enter.once="fetchItems()">
    <div v-easyadmin="easyadminId" :class="[contentType, paddingY]" :style="theme" class="themed-item" v-if="isContentVisibleToUser()" grid-instagram-component>
      <div class="container">
        <div class="row row-cols-lg-4 row-cols-md-2 row-cols-1 custom-gutter">
          <div class="col" v-for="content in contents">
            <div class="content position-relative">
              <a :href="content.permalink" target="_blank">
                <img :src="content.thumbnail_url || content.media_url" :alt="content.id" class="w-100">
                <div class="caption position-absolute-center p-3 d-flex align-items-center">
                  <div>{{content.caption}}</div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="row justify-content-center" v-if="cursorAfter">
          <div class="col-auto pt-3">
            <button class="btn btn-primary" @click="fetchItems()" :disabled="loading">{{ft("globals.load_more")}}</button>
          </div>
        </div>
      </div>
    </div>
  </intersect>
</template>
<style lang="scss">
[grid-instagram-component] {

  .row.custom-gutter { 
    margin-left: calc($fixed-grid-gap * -1);
    margin-right: calc($fixed-grid-gap * -1);

    > .col {
      padding: $fixed-grid-gap;
    }
  }

  .content {

    .caption {
      opacity: 0;
      background-color: var(--theme-background) !important;
      color: var(--theme-color) !important;
      transition: opacity $animation-time-fast;

      > div {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }

    a:hover .caption {
      opacity: 1;
    }
    
    img {
      aspect-ratio: 1 / 1;
      object-fit: cover;
    }
  }
}
</style>