<script>
import { get } from "lodash";
import { fandomBasicMixin, fandomExtendedMixin, fandomTileMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, fandomTileMixin],
  methods: {
    localizeDate(dateInfo) {
      const date = new Date(dateInfo);
      return date.toLocaleString([], { day: "numeric", month: "short" });
    }
  },
  computed: {
    button() {
      if (this.content.url) {
        return {
          title: this.content.url.title || this.ft("globals.events.tile.button"),
          url: this.content.url.url,
          target: this.getTarget(this.content.url)
        }
      } else if (this.content.button) {
        return {
          title: this.content.button.title || this.ft("globals.events.tile.button"),
          url: this.content.button.url,
          target: this.getTarget(this.content.button)
        }
      } else {
        return {
          title: this.ft("globals.events.tile.button"),
          url: this.content.slug,
          target: this.getTarget(this.content.button)
        }
      }
    },
    startDate() {
      return get(this, ["content", "valid_from"], false);
    },
    endDate() {
      return get(this, ["content", "valid_to"], false);
    },
    hasDate() {
      return this.startDate && this.endDate;
    }
  }
};
</script>

<template>
  <div class="row" v-easyadmin="easyadminId" :class="contentType" tile-event-component>
    <div class="col-12 col-md-6 col-lg-4 pr-md-0">
      <div class="bg-light">
        <div class="w-100 ar-16by9 bg-cover-center position-relative bg-light" :style="{'background-image': getContentResponsiveImageURL('thumbnail')}">
          <div class="position-absolute-center d-flex align-items-end px-2 pb-1 text-white text-capitalize" v-if="hasDate">
            <span class="d-flex align-items-center">
              <i class="fal fa-calendar-alt mb-1"></i>
              <span class="mx-2">{{localizeDate(startDate)}}</span>
              <i class="fal fa-angle-right mb-1"></i>
              <span class="mx-2">{{localizeDate(endDate)}}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-8 pl-md-0">
      <div class="d-flex flex-column justify-content-around bg-light p-3 h-100">
        <div class="pb-3">
          <h6 class="text-uppercase">{{miniformat.title}}</h6>
          <h3>{{content.title}}</h3>
        </div>
        <div class="d-flex flex-column flex-md-row align-items-md-center">
          <a class="btn btn-primary mb-3 mb-md-0 mr-md-2" :href="button.url" :target="button.target">{{button.title}}</a>
          <a class="d-inline-flex align-items-center" :href="content.button_more.url" :target="getTarget(content.button_more)" v-if="content.button_more">
            <span class="mr-2 ml-md-2">{{content.button_more.title || ft("events.tile.discover_more")}}</span>
            <i class="fal fa-long-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
