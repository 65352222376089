<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import TextSection from 'text-section';
import { EmbedAdvanced } from 'embed';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  components: {
    TextSection,
    EmbedAdvanced
  },
  data: function() {
    return {
    };
  },
  methods: {
    getContentResponsiveImageURL: Fandom.getContentResponsiveImageURL,
    mayToggleVideoPopup() {
      if (Fandom.exists(this.content.play)) {
        Vue.set(globalState, "videoPopupContent", { content: this.content, containerContent: this.containerContent });
      }
    }
  },
  computed: {
    isMobile() {
      return globalState.viewport.xs;
    },
    imageUrl() {
      if (this.isMobile && this.content.image_mobile) {
        return this.content.image_mobile.url;
      } else if (this.content.image) {
        return this.content.image.url;
      }
    }
  }
};
</script>

<template>
  <div
    v-easyadmin="easyadminId" 
    :class="[contentType, paddingY]" 
    class="bg-cover-center themed-item"
    v-if="isContentVisibleToUser()"
    :id="anchorId"
    :style="[theme, {
      'background-image': getContentResponsiveImageURL('background_image')
    }]" 
    stripe-media-component
  >
    <div class="container">
      <div class="row">
        <div class="col-12 px-0">
          <template v-if="content.play">
            <embed-advanced :content="content" 
              :opt-container-content="containerContent"
              :height-adaptive="false"
              v-if="!content.modal"
            ></embed-advanced>
            <div class="modal-container ar-16by9 bg-cover-center" :style="{'background-image': getContentResponsiveImageURL('image')}" @click="mayToggleVideoPopup()" :class="{'cursor-pointer': content.play}" v-else>
              <div class="position-absolute-center d-flex align-items-center justify-content-center flex-column px-3">
                <i class="fal fa-play-circle fa-4x my-3"></i>
                <text-section :content="{...{text_align: 'center'}, ...content}" :opt-container-content="containerContent"></text-section>
              </div>
            </div>
          </template>
          <template v-else-if="content.image">
            <img :src="imageUrl" v-if="!content.aspect_ratio" class="w-100">
            <div class="bg-cover-center" :class="content.aspect_ratio" :style="{'background-image': getContentResponsiveImageURL('image')}" v-else>
            </div>
            <div class="position-absolute-center d-flex align-items-center justify-content-center p-3">
              <text-section :content="content" :opt-container-content="containerContent"></text-section>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[stripe-media-component] {
  position: relative;

  background-color: var(--theme-background);
  color: var(--theme-color);

  @include media-breakpoint-down(md) {

    /* Forced square rappresentation on mobile */
    .modal-container.ar-16by9 {
      padding-bottom: 100%;

      .fa-8x {
        font-size: 5rem;
      }
    }
  }
}
</style>
