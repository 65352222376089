<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { youtubeBasicMixin } from "src/modules/youtube_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";
import embedVideoMixin from "src/modules/embed_video_module.js";
import { merge } from "lodash";
import {EmbedVideoControlBar} from 'embed';

export default {
  mixins: [fandomBasicMixin, youtubeBasicMixin, interactionMixin, embedVideoMixin],
  components: { EmbedVideoControlBar },
  props: {
    controlBar: {
      type: Boolean,
      default: true
    },
    areaClickable: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      youtubeConfig: this.initYoutubeConfig()
    };
  },
  watch: {
    videoPlayer: {
      handler(newValue) {
        this.$emit("video-player-handler", newValue);
      },
      deep: true
    },
    overvideoInteraction(newValue) {
      this.$emit("overvideo-interaction-handler", newValue);
    }
  },
  methods: {
    initYoutubeConfig() {
      // The videoConfig can be inherited by side effect from a parent component.
      const youtubeConfig = this.content.play.videoConfig || { controls: 1 }

      if (this.content.play.autoplay) {
        merge(youtubeConfig, youtubeConfig, {
          autoplay: 1,
          mute: 1
        })
      }

      return youtubeConfig;
    },
    unsetOvervideoInteraction() {
      this.unsetOvervideoInteractionByUserInteraction();
    }
  }
};
</script>

<template>
  <div embed-youtube-component>
    <div :id="content.youtube_id"></div>
    <div class="control-bar-container" ref="control-bar">
      <embed-video-control-bar
        :content="content"
        :control-bar="controlBar"
        :show-controls="showControls"
        :fullscreen="fullscreen"
        :video-player="videoPlayer"
        @toggle-fullscreen="$emit('toggle-fullscreen')"
        @toggle-pip="$emit('toggle-pip')"
        v-if="false && videoPlayerState!==null"
      ></embed-video-control-bar>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>