<script>
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";
import { get } from "lodash";
import { v4 as uuidv4 } from 'uuid';

export default {
  data() {
    return {
      instantwinLoading: false,
      paddingYDefault: 5,
      contentReward: null,
      timestamp: uuidv4()
    }
  },
  mixins: [fandomBasicMixin, fandomExtendedMixin, interactionMixin],
  mounted() {
  },
  methods: {
    getContentResponsiveImageURL: Fandom.getContentResponsiveImageURL,
    isUserRegistered: Fandom.isUserRegistered,
    updateIWInteraction() {
      this.instantwinLoading = true;
      this.updateInstantWinInteraction(this.containerContent, this.content, this.content.interaction, (data) => {
        this.contentReward = data.content_reward;
        // timeout needed for suspance ;)
        setTimeout(() => {
          this.instantwinLoading = false;
          const won = get(data, ["user_interaction", "data", "won"], false);
          this.timestamp = uuidv4();
          if (won) {
            $(this.$refs['winModal']).modal('show');
            this.notify();
          } else {
            $(this.$refs['loseModal']).modal('show');
          }
        }, 1000);
      });
    },
    notify() {
      Fandom.ajax({
        url: "/api/v5/update_user_interaction/iw/winner_notify",
        method: "POST",
        data: {
          interaction_id: this.content.interaction.id,
          content_cache_id: this.containerContent.id
        },
        success: (_) => {
          Fandom.emit("hide-loading-spinner");
        }
      });
    }
  },
  computed: {
    currency() {
      return get(this.content, "currency", null);
    },
    userTickets() {
      return Fandom.getUserReward(this.currency);
    },
    instantwinAvailable() {
      const now = new Date();
      return (new Date(this.content.valid_from) <= now || !this.content.valid_from) && 
             (now <= new Date(this.content.valid_to) || !this.content.valid_to);
    },
    alreadyWon() {
      const interactionId = get(this.content, ["interaction", "id"], -1);
      return get(globalState.pageModel.name_to_content_user_info, [this.containerContent.name, "id_to_interaction", interactionId, "user_interaction", "data", "won"], false);
    },
    instantwinStatus() {
      if (this.instantwinAvailable) {
        return this.alreadyWon ? "already-won" : "playable";
      } else {
        return "unavailable";
      }
    },
    winImage() {
      return this.content?.popup_assets?.win?.url || this.contentReward?.image?.url;
    },
    loseImage() {
      return this.content?.popup_assets?.lose?.url;
    },
    loaderIcon() {
      return this.content?.popup_assets?.loading || 'fa-light fa-crown';
    },
    rootClasses() {
      return [
        this.contentType,
        this.paddingY,
        "themed-item",
        "bg-cover-center"
      ];
    },
    contentStyle() {
      const height = get(this, ["content", "height"], "auto");
      return {
        "min-height": height
      };
    },
    winFormattedText() {
      return this.ft("instantwin.points_available", { counter: this.userTickets });
    }
  }
};
</script>

<template>
  <div
    v-easyadmin="easyadminId"
    :class="rootClasses"
    :style="[theme, {'background-image': getContentResponsiveImageURL('background_image')}]"
    instantwin-component
  >
    <div class="container">
      <div class="row justify-content-center align-items-center" :style="contentStyle">
        <div class="col-12 col-lg-6 text-center">
          <h1 class="text-uppercase" v-if="alreadyWon">{{ft("instantwin.already_won")}}</h1>
          <h1 class="text-uppercase" v-else-if="!instantwinAvailable">{{ft("instantwin.unavailable")}}</h1>
          <h1 class="text-uppercase" v-else-if="userTickets > 0">{{winFormattedText}}</h1>
          <h1 class="text-uppercase" v-else>{{ft("instantwin.lose_modal.no_more_tickets")}}</h1>

          <div class="ticket-badge d-flex justify-content-center my-4 my-md-5" v-if="!alreadyWon && instantwinAvailable">
            <div class="d-flex align-items-center justify-content-center bg-yellow text-dark">
              <span class="h1 mb-0 mr-3"><span v-if="userTickets > 0">+</span>{{userTickets}}</span>
              <i class="fa-light fa-ticket-simple fa-rotate-by" style="--fa-rotate-angle: 125deg;""></i>
            </div> 
          </div>

          <template v-if="isUserRegistered">
            <button v-if="!alreadyWon && instantwinAvailable && userTickets > 0" 
              class="btn text-uppercase p-4 mb-4"
              :class="themeButtonClass"
              @click="updateIWInteraction">
              {{ft("instantwin.play")}}
            </button>
            <div v-if="alreadyWon && content.winner_message">
              <div v-if="content.winner_message.description" v-html="content.winner_message.description"></div>
              <a v-if="content.winner_message.button"
                :href="content.winner_message.button.url"
                :target="getTarget(content.winner_message.button)"
                class="btn btn-light p-4 mb-4">
                {{content.winner_message.button.title}}
              </a>
            </div>
            <div v-if="!alreadyWon && !userTickets && content.noticket_message">
              <div v-if="content.noticket_message.description" v-html="content.noticket_message.description"></div>
              <a v-if="content.noticket_message.button"
                :href="content.noticket_message.button.url"
                :target="getTarget(content.noticket_message.button)"
                class="btn btn-light p-4 mb-4">
                {{content.noticket_message.button.title}}
              </a>
            </div>
          </template>
          <a :href="'/users/sign_up'" class="btn btn-primary p-4 mb-4" v-else>{{ft("instantwin.play")}}</a>          
          <div class="small" v-if="content.description" v-html="content.description"></div>
        </div>
      </div>
    </div>
    <!-- LOADING LAYER -->
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
      <div class="loading-layer d-flex justify-content-center align-items-center text-white" v-if="instantwinLoading">
        <i class="fa-flip fa-10x" :class="loaderIcon"></i>
      </div>
    </transition>
    <!-- WIN MODAL -->
    <div class="modal fade" ref="winModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-0 pb-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <i aria-hidden="true" class="fa-regular fa-xmark"></i>
            </button>
          </div>
          <div class="modal-body text-dark text-center px-4 pb-5">
            <img v-if="winImage" class="w-50 mb-4" :src="winImage" :key="`force-win-loading-${timestamp}`">
            <h2 class="modal-title text-success">{{ft("instantwin.win_modal.title")}}</h2>
            <div v-if="contentReward">
              <h3 class="modal-title">{{contentReward.title}}</h3>
              <div class="mt-4">
                <div v-if="contentReward.description" v-html="contentReward.description"></div>
                <div v-if="content.winner_message && content.winner_message.description" v-html="content.winner_message.description"></div>
              </div>
            </div>
            <a
              v-if="content.winner_message && content.winner_message.button"
              :href="content.winner_message.button.url"
              :target="getTarget(content.winner_message.button)"
              class="btn btn-primary mt-4"
            >
              {{content.winner_message.button.title}}
            </a>
            <button v-else type="button" class="btn btn-primary mt-4" data-dismiss="modal" aria-label="Close">{{ ft("globals.close") }}</button>
          </div>
        </div>
      </div>
    </div>
    <!-- LOSE MODAL -->
    <div class="modal fade" ref="loseModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header border-0 pb-0">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <i aria-hidden="true" class="fa-regular fa-xmark"></i>
            </button>
          </div>
          <div class="modal-body text-dark text-center px-4 pb-5">
            <img v-if="loseImage" class="w-50 mb-4" :src="loseImage" :key="`force-lose-loading-${timestamp}`">
            <h2 class="modal-title text-danger">{{ ft("instantwin.lose_modal.title") }}</h2>
            <h3 class="modal-title text-dark">{{ ft("instantwin.lose_modal.description") }}</h3>
            <button type="button" class="btn btn-primary mt-4" data-dismiss="modal" aria-label="Close">{{ ft("globals.close") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[instantwin-component] {

  .ticket-badge > div {
    padding: 1rem 1rem 0.75rem 1rem;
    border-radius: 2.5rem;
    width: 10rem;

    i { font-size: 1.75rem; }
  }

  .loading-layer {
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
