<script>
export default {
  data() {
    return {
      passwordField: null,
      passwordVisible: false
    };
  },
  mounted() {
    this.passwordField = $("input[type='password']");
  },
  methods: {
    toggleVisibility() {
      if (this.passwordVisible) {
        $(this.passwordField).attr('type', 'password');
      } else {
        $(this.passwordField).attr('type', 'text');
      }

      this.passwordVisible = !this.passwordVisible;
    }
  }
};
</script>

<template>
  <div
    class="input-group-append"
    @click="toggleVisibility()"
    password-visibility-switch-component
  >
    <span class="input-group-text bg-white d-flex justify-content-center align-items-center p-0 cursor-pointer">
      <i class="fa-solid" :class="passwordVisible ? 'fa-eye-slash' : 'fa-eye'"></i>
    </span>
  </div>
</template>

<style lang="scss" scoped>
[password-visibility-switch-component] {
  .input-group-text {
    width: $input-height;
  }
}
</style>