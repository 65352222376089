<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module.js';
import { liveEventsModalMixin } from 'src/modules/live_events_v2_module.js';

export default {
  mixins: [fandomBasicMixin, liveEventsModalMixin],
  mounted() {
    this.openModal();
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" modal page-live-events-interaction-modal-component>
    <transition 
      enter-active-class="animated fadeIn faster" 
      leave-active-class="animated fadeOut faster" 
    >
      <div class="live-event-modal__background" v-if="showModal"></div>
    </transition>
    <transition
      enter-active-class="animated slideInDown faster" 
      leave-active-class="animated slideOutUp faster" 
    >
      <div class="live-event-modal d-flex justify-content-center align-items-center h-100vh w-100vw" v-if="showModal">
        <div class="interaction-modal__content position-relative">
          <div class="h-100 py-5 px-4 d-flex flex-column justify-content-between align-items-center widget-wrapper">
            <i @click="closeModal()" class="fal fa-lg fa-times p-4 cursor-pointer position-absolute"></i>
            <component @interaction-updated="deactivateModal()" :inherit-theme-type="'dark'" class="my-0" :is="content.layout" :content="content" :opt-container-content="containerContent"></component>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
[page-live-events-interaction-modal-component] {

  .live-event-modal {
    .fa-times {
      top: 0;
      right: 0;
    }
    .interaction-modal__content {
      max-width: 600px;
      border-radius: 12px;
      background-color: var(--theme-background);
      color: var(--theme-color);
    }
  }

  @include ie {
    .widget-wrapper {
      min-width: 37.5rem;
    }
  }
}
</style>
