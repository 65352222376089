<script>
import { fandomBasicMixin } from 'src/modules/fandom_mixins_module';
import { cloneDeep, shuffle } from "lodash";
import showdown from 'showdown';
import { mqLess } from 'src/modules/viewport_module';

export default {
  mixins: [fandomBasicMixin],
  props: {
    init: Object
  },
  data: function() {
    return {
      converter: new showdown.Converter(),
      messages: [],
      chatId: null,
      runId: null,
      message: { role: "user", content: "", file_ids: [], image_url: "" },
      tips: null,
      loading: false,
      file: {
        loading: false,
        vision: "",
        data: {},
        preview: null
      },
      // stream data
      queue: [],
      isDisplaying: false
    };
  },
  mounted() {
    if (this.init) {
      this.messages = this.init.messages || [];
      if (!this.messages.length && this.content.init_message) {
        this.messages.push({ role: "assistant", content: this.content.init_message });
        this.tips = shuffle(this.content.tips).slice(0, mqLess("md") ? 2 : 4);
      }
      this.chatId = this.init.id;
    } else {
      this.createChat();
      this.tips = shuffle(this.content.tips).slice(0, mqLess("md") ? 2 : 4);
    }
  },
  methods: {
    createChat() {
      this.loading = true;
      Fandom.ajax({
        method: "POST",
        url: Fandom.applyContextToUrl("/api/v5/ai/chat/create"),
        data: { content_name: this.content.name },
        success: (data) => {
          if (data.success) {
            this.chatId = data.chat.id;
            this.messages.push({ role: "assistant", content: this.content.init_message });
          } else {
            console.log("%cERROR: in chat creation", "color: red", data);
          }
          this.loading = false;
        }
      });
    },
    applyTip(tip) {
      this.message.content = tip.message;
      this.tips = null;
      Vue.nextTick(() => { this.resizeTextarea(); });
    },
    resizeTextarea() {
      const textarea = this.$refs.textarea;
      textarea.style.height = "3rem";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    mayChat(event) {
      if (event && event.keyCode == 13) {
        this.chat();
      }
    },
    // uploadImage(event) {
    //   this.file.loading = true;

    //   const formData = new FormData(); 
    //   const file = event.target.files[0];
    //   formData.append("file", file);
      
    //   Fandom.ajax({
    //     processData: false, 
    //     contentType: false,
    //     method: "POST",
    //     url: Fandom.applyContextToUrl("/api/v5/ai/chat/file"),
    //     data: formData,
    //     success: (data) => {
    //       Vue.set(this.file, "data", { id: data.fileId, name: file.name });   
    //       this.message.file_ids.push(data.fileId);
    //       this.file.loading = false; 
    //     }
    //   });
    // },
    // Temporary implementation while waiting for the use of images in assistant chat to be completed.
    async uploadImage(event) {
      this.file.loading = true;
      const originalfile = event.target.files[0];
      
      const reader = new FileReader();
      reader.onload = (e) => {this.file.preview = e.target.result}
      reader.readAsDataURL(originalfile);

      if (originalfile) {
        const file = new File([originalfile], `${originalfile.name.replace(/[^a-zA-Z0-9\._]/g, '_')}`, {type: originalfile.type});
        const reqOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': $("meta[name=\"csrf-token\"]").attr("content")
          }
        };
        let response = await fetch(Fandom.applyContextToUrl(`/api/v5/get_aws_presigned_url?filename=${file.name}`), reqOptions);
        let data = await response.json();

        const awsReqOptions = {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
            'Access-Control-Allow-Origin': 'no-cors'
          }
        };

        await fetch(data.signed_url, awsReqOptions);
        reqOptions.method = "POST";
        reqOptions.body = JSON.stringify({ imageUrl: data.signed_url.split("?")[0] })
        response = await fetch(Fandom.applyContextToUrl(`/api/v5/ai/chat/image`), reqOptions);
        data = await response.json();
        this.$refs.attachment.value = "";
        this.file.loading = false; 
        this.file.vision = data.content;
        this.message.image_url = data.url;
        this.file.data = {};
      }
    },
    chat() {
      if (this.message.content) {
        this.loading = true;
        const data = { 
          name: this.chatId,
          content_cache_id: this.containerContent.id,
          messages: JSON.stringify([this.message]),
          visionMessage: this.file.vision,
          fileId: this.file.data.id // Temporary unused
        }

        this.messages.push(cloneDeep(this.message));
        this.resetMessage();
        this.tips = null;
        Vue.nextTick(() => { 
          this.resizeTextarea(); 
          this.$refs.conversation.scrollTo(0, this.$refs.conversation.scrollHeight);
        });

        Fandom.ajax({
          method: "POST",
          data: data,
          url: Fandom.applyContextToUrl("/api/v5/ai/chat/send"),
          success: (data) => {
            this.resetFile();
            if (data.status == 'queued' && data.jwt) {
              this.streamData(data.jwt);
            } else { // Scenario used in chat model
              this.messages.push(data.text);
              this.loading = false;
            }
          }
        });
      } 
    },
    streamData(jwt) {
      const url = Fandom.applyContextToUrl(`/api/v5/ai/stream-chat?jwt=${jwt}`);
      const eventSource = new EventSource(url);
      eventSource.onmessage = (event) => {
        if (this.loading) {
          this.messages.push({ role: "assistant", content: "" });
          this.loading = false;
        }
        
        const data = JSON.parse(event.data);
        if (data.error) {
          console.error('Error:', data.error);
          eventSource.close();
        } else if (data.finish_reason == "stop") {
          eventSource.close();
        } else if (data.starter == true) {
          console.log("context:", data.context);
        } else if (data.message) {
          this.queue.push(data.message)
          if (!this.isDisplaying) {
            this.processQueue();
          }
        }
      };

      eventSource.onerror = () => {
        eventSource.close();
      }
    },
    displayCharacterByCharacter(text, index = 0) {
      this.$refs.conversation.scrollTo(0, this.$refs.conversation.scrollHeight);
      if (index < text.length) {
        const newText = `${this.messages[this.messages.length-1].content}${text.charAt(index)}`
        Vue.set(this.messages[this.messages.length-1],"content", newText);
        // recursively call next display
        setTimeout(() => this.displayCharacterByCharacter(text, index + 1), 10);
      } else {
        this.isDisplaying = false;
        this.processQueue();
      }
    },
    processQueue() {
      if (this.queue.length > 0 && !this.isDisplaying) {
        const text = this.queue.shift();
        this.isDisplaying = true;
        this.displayCharacterByCharacter(text);
      }
    },
    resetMessage() {
      this.message = { role: "user", content: "", file_ids: [], image_url: "" };
    },
    resetFile() {
      this.file = {
        loading: false,
        vision: "",
        data: {},
        preview: null,
        url: ""
      }
    }
  },
  computed: {
    chatMessages() {
      return this.messages.filter(i => !i.hidden)
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" class="bg-light" page-chat-component>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 px-0">
          <div class="conversation">
            <div class="conversation-inset h-100 w-100 d-flex flex-column justify-content-between">
              <div class="conversation-body px-3" ref="conversation">
                <div v-for="message in chatMessages" :class="message.role" class="message d-flex">
                  <div class="message-l">
                    <div class="icon" v-if="message.role == 'assistant'"><i class="fa-light fa-robot"></i></div>
                    <div class="icon" v-else><i class="fa-light fa-user"></i></div>
                  </div>
                  <div class="message-r d-flex flex-column">
                    <img v-if="message.image_url" class="img-fluid w-50 pb-3" :src="message.image_url">
                    <div v-html="converter.makeHtml(message.content)"></div>
                  </div>
                </div>
                <div class="assistant message d-flex" v-if="loading">
                  <div class="message-l"><div class="icon"><i class="fa-light fa-robot"></i></div></div>
                  <div class="mt-1">
                    <i class="fa-duotone fa-circle fa-bounce small"></i>
                    <i class="fa-duotone fa-circle fa-bounce small" style="--fa-animation-delay: .25s;"></i>
                    <i class="fa-duotone fa-circle fa-bounce small" style="--fa-animation-delay: .5s;"></i>
                  </div>
                </div>
              </div>
              <div class="conversation-footer p-3">
                <div class="conversation-tip pb-3" v-if="tips && tips.length > 0">
                  <ul class="d-flex flex-wrap list-unstyled mb-0 mx-n2">
                    <li @click="applyTip(tip)" v-for="tip in tips">
                      <div class="small mx-2 mt-2">{{tip.message}}</div>
                    </li>
                  </ul>
                </div>
                <div class="conversation-form">
                  <form @submit.prevent="chat()" class="form">
                    <div class="form-group mb-0">
                      <span v-if="file.preview" class="preview-image bg-cover-center bg-light" :style="{backgroundImage: `url(${file.preview})`}"></span>
                      <div>
                        <textarea @keyup="resizeTextarea" @keydown="mayChat" ref="textarea" type="text" class="form-control" v-model="message.content" :readonly="loading"></textarea>
                        <input type="file" class="d-none" ref="attachment" id="data-attachment" @change="uploadImage($event)">
                        <label for="data-attachment" class="conversation-action conversation-action-left mb-0">
                          <i class="fa-light fa-paperclip" :class="{'fa-bounce': file.loading}"></i>
                        </label>
                        <button :disabled="file.loading" class="btn conversation-action conversation-action-right" type="submit"><i class="fa-light fa-arrow-up"></i></button>
                      </div>
                    </div>
                  </form>
                  <div v-if="file.data.name" class="pt-3">
                    <div class="file">{{file.data.name}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
[page-chat-component] {
  --input-height: 3rem;
  --icon-height: 2rem;

  .container {
    max-width: 850px;
  }

  .conversation {
    height: calc(100dvh - var(--main-navbar-height));
    
    .conversation-inset {
      overflow: hidden;

      .conversation-body {
        overflow-y: auto;
      }

      .conversation-footer {
        .conversation-form {
          .form-group {
            position: relative;
            border-radius: 0.5rem;
            border: 1px solid #ced4da;
            display: flex;
            flex-direction: column;
            
            textarea {
              padding: 0.75rem 3rem;
              border-radius: 0.5rem;
              height: var(--input-height);
              resize: none;
              overflow: hidden;
              border: 0;
            }
          }

          .preview-image {
            width: 3.5rem;
            height: 3.5rem;
            border-radius: .5rem;
            margin: .5rem;
          }

          .conversation-action {
            position: absolute;
            bottom: 0.5rem;
            width: calc(var(--input-height) - 1rem);
            height: calc(var(--input-height) - 1rem);
            background-color: $gray-200;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.5rem;
            cursor: pointer;

            i {
              font-size: 1.25rem;
            }

            &.conversation-action-left {
              left: 0.5rem;
            }

            &.conversation-action-right {
              right: 0.5rem;
            }

            &.btn {
              outline: none !important;
              box-shadow: none !important;
              padding: 0;
              background-color: $dark;
              color: $light;
            }
          }
        } 

        .file {
          display: inline;
          background-color: $gray-200;
          padding: 0.5rem;
          border-radius: 0.5rem;
        }

        .conversation-tip {
          li {
            width: 50%;

            @include media-breakpoint-down(md) {
              width: 100% !important;
            }

            > div {
              border: 1px solid $gray-200;
              color: $gray-600;
              padding: 0.5rem;
              border-radius: 0.5rem;
              cursor: pointer;
            }      
          }
        }
      }

      .message {
        margin-top: 2rem;

        .icon {
          width: var(--icon-height);
          height: var(--icon-height);
          line-height: var(--icon-height);
          text-align: center;
          border-radius: 100%;
          margin-right: 1rem;

          i {
            position: relative;
            top: 1px;
          }
        }

        &.assistant {
          .icon {
            background-color: $primary;
            color: $white;
          }

          img {
            width: 300px;
            max-width: 100%;
            margin: 1.5rem 0 1.5rem 0;
            display: block;
            border-radius: 0.5rem;
            aspect-ratio: 1/1;
            object-fit: cover;
          }

          h1, h2, h3 { 
            margin-top: 1.5rem; 
            font-size: 1.5rem;
          }
        }

        &.user {
          .icon {
            background-color: $gray-200;
            color: $black;
          }
        }
      }
    }
  }
}
</style>
