<script>
import { get } from "lodash";
import { fandomBasicMixin, fandomExtendedMixin } from "src/modules/fandom_mixins_module.js";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin],
  data() {
    return {
      paddingYDefault: 5
    };
  },
  methods: {
    isFirst(index) {
      return index === 0;
    },
    isLast(index) {
      return index === this.content.items.length - 1;
    }
  },
  computed: {
    columnClasses() {
      const size = get(this, ["content", "width"], 8);
      return [`col-md-${size}`];
    },
    containerClasses() {
      return this.propagateBackground ? "themed-item" : "";
    },
    containerStyles() {
      return {
        "background-image": this.propagateBackground ? this.backgroundImage : ""
      };
    },
    contentClasses() {
      return this.propagateBackground ? "" : "themed-item";
    },
    contentStyles() {
      return {
        "min-height": this.content.height,
        "background-image": this.propagateBackground ? "" : this.backgroundImage
      };
    },
    backgroundImage() {
      return this.getContentResponsiveImageURL("background_image");
    },
    propagateBackground() {
      return get(this, ["content", "propagate_background"], false);
    },
    gridStyles() {
      return {
        "--timeline-color": get(this, ["content", "timeline_color"], null)
      };
    },
    fullDots() {
      return get(this, ["content", "full_dot"], false);
    }
  },
};
</script>

<template>
  <div v-easyadmin="easyadminId" v-if="isContentVisibleToUser()" :class="contentType" :style="theme" list-bullet-component>
    <div class="w-100 bg-cover-center" :class="[containerClasses, paddingY]" :style="containerStyles">
      <div class="container">
        <div class="row justify-content-center bg-cover-center" :class="contentClasses" :style="contentStyles">
          <div class="col-12" :class="columnClasses">
            <div class="grid" :style="gridStyles">
              <template v-for="(item, index) in content.items">
                <div class="text-part number text-right font-weight-bold" :key="`number-${index}`">
                  {{ item.number || '' }}
                </div>
                <div class="h-100 d-flex flex-column align-items-center" :key="`divider-${index}`">
                  <div class="divider top-part" :class="{'bg-timeline': !isFirst(index)}"></div>
                  <div class="dot rounded-circle" :class="{'full': fullDots}"></div>
                  <div class="divider bottom-part" :class="{'bg-timeline': !isLast(index)}"></div>
                </div>
                <div class="d-flex flex-column ml-2 text-part" :key="`desc-${index}`">
                  <div class="h4 mb-0 font-weight-normal title" v-if="item.title">
                    {{item.title}}
                  </div>
                  <p v-html="item.description" class="text-black description" v-if="item.description"></p>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$item-size: 1rem;
$line-height: 1.5;
$divider-size: .125rem;
$top-size: calc(#{$item-size} * #{$line-height} / 2);
$bottom-size: calc(100% - #{$top-size});

[list-bullet-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);

  .text-part {
    font-size: $item-size;
    line-height: $line-height;
    min-height: calc(#{$item-size} * #{$line-height} * 2);
  }

  .divider {
    width: $divider-size;
  }

  .top-part {
    height: calc(#{$top-size} - (#{$item-size} / 2));
  }

  .bottom-part {
    height: calc(#{$bottom-size} - (#{$item-size} / 2));
  }

  .dot {
    height: $item-size;
    width: $item-size;
    background-color: transparent;
    border: $divider-size solid var(--timeline-color);

    &.full {
      background-color: var(--timeline-color);
    }
  }

  .bg-timeline {
    background-color: var(--timeline-color);
  }

  .title {
    font-size: 1.125em;
  }

  .description {
    font-size: .875em;
  }

  .grid {
    display: grid;
    grid-template-columns: auto $item-size 1fr;
    grid-gap: 0 $item-size;
    --timeline-color: #{$primary};
  }
}
</style>
