import { isEmpty, isNil, negate, get, set, map, merge, concat, startsWith } from "lodash";
import { fandomTranslate } from "src/modules/common_module";
import { sendCookieAccepted } from "src/modules/fandom_analytics_module";
import { getAuthorizationHeaders } from 'src/modules/embedded_module';
import Vue from "vue/dist/vue.esm";
import * as urljoinAux from "url-join";
require("jquery-inview/jquery.inview.min.js");

// cq

// workaround to a strange behaviour of urljoin: urljoin("", "/a/b") => "a/b"
function urljoin() {
  if (isEmpty(arguments[0])) {
    arguments[0] = "/";
  }
  return urljoinAux(arguments);
}

function getCSRFHeader() {
  return {'X-CSRF-Token': $("meta[name=\"csrf-token\"]").attr("content")}
}

/**
 * Retries the given function until it succeeds given a number of retries and an interval between them. They are set
 * by default to retry 5 times with 1sec in between. There's also a flag to make the cooldown time exponential
 * @param {Function} fn - Returns a promise
 * @param {Number} retriesLeft - Number of retries. If -1 will keep retrying
 * @param {Number} interval - Millis between retries. If exponential set to true will be doubled each retry
 * @param {Boolean} exponential - Flag for exponential back-off mode
 * @return {Promise<*>}
 */
async function retry(fn, retriesLeft = 5, interval = 1000, exponential = false) {
  try {
    const response = await fn();
    if (response instanceof Response && !response.ok) {
      throw new Error('Something went wrong');
    }

    return response;
  } catch (error) {
    if (retriesLeft) {
      await new Promise(r => setTimeout(r, interval));
      return retry(fn, retriesLeft - 1, exponential ? interval * 2 : interval, exponential);
    } else {
      throw new Error(`Max retries reached for function ${fn.name}`);
    }
  }
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


// This function can be used to execute another function just once; it's useful to call a function
// once in a vue template (to implement a kind of jquery's onDocumentReady).
// The id parameter is used to set a boolean for the test.
// The function is actually execute just once for each id.
const executeOnceIds = {};
function executeOnce(id, funct) {
  if (!executeOnceIds.hasOwnProperty(id)) {
    executeOnceIds[id] = true;
    funct();
  }
}

function repeatUntil(fn, conditionFn, interval=500) {
  const isTrue = conditionFn();
  if (!isTrue) {
    setTimeout(()=>repeatUntil(fn, conditionFn, interval), interval);
  } else {
    fn();
  }
}

// Warning: some problem in pageSpeed in thumb mode
// function changeAttachmentSizing(url, size = "original") {
//   const parts = url.split("/");
//   const sizingPart = parts.length - 2;
//   parts[sizingPart] = size;
//   return parts.join("/");
// }

// This function can be used to send Facebook CAPI events in GTM.
function sendFandomFacebookAnalytics(event_name, event_id, fbp, test_event_code) {
  Fandom.ajax({
    url: "/api/v5/tracking/facebook",
    method: "POST",
    data: { event_name: event_name, event_id: event_id, fbp: fbp, test_event_code: test_event_code },
    success: (data) => {
      console.log("%cfandom facebook analytics\n\tresult: " + JSON.stringify(data), "color: #aaaaaa");
      return data;
    }
  });
  return null;
}

function flickityIOsFixAux(carouselClass) {
  var touchingCarousel = false,
    touchStartCoords;

  document.body.addEventListener('touchstart', function(e) {
    if (e.target.closest(carouselClass)) {
      touchingCarousel = true;
    } else {
      touchingCarousel = false;
      return;
    }

    touchStartCoords = {
      x: e.touches[0].pageX,
      y: e.touches[0].pageY
    }
  });

  document.body.addEventListener('touchmove', function(e) {
    if (!(touchingCarousel && e.cancelable)) {
      return;
    }

    var moveVector = {
      x: e.touches[0].pageX - touchStartCoords.x,
      y: e.touches[0].pageY - touchStartCoords.y
    };

    if (Math.abs(moveVector.x) > 7)
      e.preventDefault()

  }, {passive: false});
}

function flickityIOsFix(carouselClasses) {
  carouselClasses.forEach(carouselClass => {
    flickityIOsFixAux(carouselClass);
  });
}

function isTouchSupported() {
  return "ontouchstart" in window || navigator.msMaxTouchPoints;
}

function isTouchDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

function isIOsDevice() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

function isImage(url) {
  if (url) {
    const supportedExtensions = ["jpeg", "jpg", "gif", "png", "apng", "svg", "bmp", "ico", "webp"];
    const nameArray = url.split('.');
    return supportedExtensions.includes(nameArray[nameArray.length - 1].split('?')[0]);
  } else {
    return false;
  }
}

function isAudio(url) {
  return /\.(mp3|wav)$/i.test(url);
}

function capitalize(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  } else {
    return string;
  }
}

function getContextPath() {
  return globalState.pageModel.context_path;
}

function applyContextToUrl(url) {
  const context = getContextPath();
  if (!exists(url)) {
    return url;
  } else if (/^(https?:)?\/\//i.test(url)) {
    return url;
  } else if (startsWith(url, `${context}/`)) {
    return url;
  }
  return urljoin(context, url);
}


const AJAX_LOCKS = {};
function ajax({ url, method = "POST", data = {}, processData, contentType, success = (() => {}), error = (() => {}), xhr, async = true }, complete = (() => {})) {
  const ajax_lock_key = `${url}-${JSON.stringify(data)}`;
  const contextUrl = applyContextToUrl(url);
  if (!exists(AJAX_LOCKS[ajax_lock_key])) {
    AJAX_LOCKS[url] = true;
    return $.ajax({
      async: async,
      method: method,
      processData: processData,
      contentType: contentType,
      xhr: xhr,
      data: data,
      headers: getAuthorizationHeaders(),
      url: contextUrl,
      beforeSend(jqXHR) {
        jqXHR.setRequestHeader("X-CSRF-Token", $("meta[name=\"csrf-token\"]").attr("content"));
      },
      success: (data, textStatus, jqXHR) => {
        delete AJAX_LOCKS[ajax_lock_key];
        success(data, textStatus, jqXHR);
      },
      error: (jqXHR, textStatus, errorThrown) => {
        delete AJAX_LOCKS[ajax_lock_key];
        error(jqXHR, textStatus, errorThrown);
      },
      complete: () => complete()
    });
  }
}

function post({ url, data = {}, success = (() => {}), error = (() => {}) }, complete = (() => {})) {
  return ajax({
    url: url,
    // data: data,
    type: "POST",
    contentType: "application/json",
    data: JSON.stringify(data),
    success,
    error,
    complete
  });
}

const exists = negate(isNil);

function acceptCookies(domain) {
  const url = domain || window.location.hostname;
  const d = new Date();
  d.setFullYear(d.getFullYear() + 1);
  document.cookie = `user_cookies=true;domain=${url};expires=${d.toUTCString};path=/;`;
  sendCookieAccepted();
}

function setCookie(name, value, expires) {
  const expiresString = exists(expires) ? `expires=${expires}` : `${expires}`;
  document.cookie = `${name}=${value};${expiresString};path=/;`;
}

function expandUrl(url, baseUrlPlaceholder = "$S3_ALIAS") {
  if (exists(url) && typeof url === "string" && url.startsWith(baseUrlPlaceholder)) {
    if (exists(globalState.pageModel.tenant.s3_base_url)) {
      return url.replace(baseUrlPlaceholder, globalState.pageModel.tenant.s3_base_url);
    }
  }
  return url;
}

function getContentURL(content) {
  if (content.hasOwnProperty("url")) {
    return content.url.url !== "$empty" ? content.url.url : "javascript: void(0)";
  } else {
    return applyContextToUrl(content.slug);
  }
}

function scrollToDirection(id, offset, direction) {
  const scrollId = id.charAt(0) === "#" ? id : `#${id}`;
  const margin = exists(offset) ? offset : 0;
  const position = $(scrollId).offset().top - margin + (direction === "bottom" ? $(scrollId).height() : 0);

  $("html, body").animate({
    scrollTop: position
  }, 0, "linear");
}

function scrollToTop(id, offset) {
  scrollToDirection(id, offset, "top");
}

function scrollToBottom(id, offset) {
  scrollToDirection(id, offset, "bottom");
}

// deprecated
function getFromQueryString(queryString, name) {
  const searchName = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp(`[\\?&]${searchName}=([^&#]*)`);
  const results = regex.exec(queryString);
  return exists(results) ? decodeURIComponent(results[1].replace(/\+/g, " ")) : null;
}

// deprecated
function getUrlParameter(name) {
  return getFromQueryString(location.search, name);
}

function getQueryStringParams() {
  if (window.location.search) {
    return new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
  }
  return {};
}

function getJSONUrlParameter(name) {
  const param = getUrlParameter(name);
  return param && JSON.parse(param);
}

function readCookie(name) {
  const matches = document.cookie.match(`(^|[^;]+)\\s*${name}\\s*=\\s*([^;]+)`);
  return Fandom.exists(matches) ? matches[0] : null;
}

function deleteCookie(name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

function isSafari() {
  return navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.indexOf("Chrome") === -1;
}

function isUserRegistered() {
  return exists(globalState.pageModel.user) && !exists(globalState.pageModel.user.anonymous);
}

function getBackgroundUrl(url, format = null) {
  if (exists(url)) {
    // url = globalState.viewport.xs ? changeAttachmentSizing(url, "thumb") : url;
    if(format) {
      url = url.replace('/original/', `/${format}/`);
    }
    return `url("${expandUrl(url)}")`;
  } else {
    return "";
  }
}

function retrieveFromGlobalState(key, fallback) {
  return get(globalState, ["pageModel", "layout", "content", key], fallback);
}

function mayGetAsset(assetName, content, containerContent, fallbackValue) {
  return get(content, [assetName], false) || get(containerContent, [assetName], false) || retrieveFromGlobalState(assetName, fallbackValue);
}

function getMainRewardName(content, containerContent) {
  return mayGetAsset("reward_name", content, containerContent, "point");
}

function mayGetAudioAsset(assetName, content, containerContent) {
  return mayGetAsset(assetName, content, containerContent, false);
}

function getUserReward(rewardName) {
  return get(globalState, ["pageModel", "user", "reward_name_to_counter", rewardName], 0);
}

function setUserReward(rewardName, value) {
  set(globalState, ["pageModel", "user", "reward_name_to_counter", rewardName], value);
}

function getUserMainRewardCount(content, containerContent) {
  const mainRewardName = getMainRewardName(content, containerContent);
  return getUserReward(mainRewardName);
}

function setUserMainRewardCount(content, containerContent, newValue) {
  const mainRewardName = getMainRewardName(content, containerContent);
  setUserReward(mainRewardName, newValue);
}

function getCurrencyCount(content) {
  const currencyName = get(content, ["instantwin", "currency"], false);
  return currencyName ? getUserReward(currencyName) : 0;
}

function setCurrencyCount(content, value) {
  const currencyName = get(content, ["instantwin", "currency"], false);
  if (currencyName) {
    setUserReward(currencyName, value);
  }
}

// TODO make component or mixin
function getNextContent(component) {
  ajax({
    type: "GET",
    data: {
      current_content_name: component.content.name
    },
    url: applyContextToUrl("/page/next_content"),
    success: (data) => {
      if (data.hasOwnProperty("next_content") && component.content.name !== data.next_content.name) {
        component.content.next_content = data.next_content;
        globalState.pageModel.name_to_content[component.content.next_content.name] = component.content.next_content;
      }
    }
  });
}

function appendContents(contentName, successCallback, contents_count, customParams, event) {
  const params = {
    exclude_content_ids: map(globalState.pageModel.name_to_content, c => c["id"]).concat(
                         (globalState.pageModel.name_to_content[contentName].exclude_content_ids || [])),
    limit: contents_count || 8,
  };

  if (!!customParams) {
    Object.keys(customParams).forEach(paramKey => {
      params[paramKey] = customParams[paramKey];
    });
  }

  return getContents(contentName, params, successCallback, true, event);
}

// Counterpart of Rails's get_contents(). successBlock can be used to update isotope grids (for example),
// while appendContents is used to handle "load more" on grids etc.
function getContents(contentName, data={}, successCallback, appendContents = false, event) {
  data["main_content_name"] = contentName;
  post({
    data,
    url: "/api/v5/get_contents",
    success: (response) => getContentsCallback(contentName, response, successCallback, appendContents)
  });
}

function getSearchableContents(successBlock, data, appendContents = false) {
  post({
    data,
    url: "/api/v5/get_searchable_contents",
    success: (response) => getContentsCallback(response.main_content_name, response, successBlock, appendContents)
  });
}

function getRandomContents(successBlock, params, appendContents = false) {
  post({
    data: params,
    url: "/api/v5/get_random_contents",
    success: (data) => {
      const mainContentName = data.main_content_name;
      const mainContent = globalState.pageModel.name_to_content[mainContentName];
      if (!mainContent) {
        Vue.set(globalState.pageModel.name_to_content, mainContentName, data.name_to_content[mainContentName]);
      }
      getContentsCallback(mainContentName, data, successBlock, appendContents);
    }
  });
}

function getContentsByNames(successBlock, params, appendContents = false) {
  return post({
    data: params,
    url: "/api/v5/get_contents_by_names",
    success: (data) => {
      const mainContentName = data.main_content_name;
      const mainContent = globalState.pageModel.name_to_content[mainContentName];
      if (!mainContent) {
        Vue.set(globalState.pageModel.name_to_content, mainContentName, data.name_to_content[mainContentName]);
      }
      getContentsCallback(mainContentName, data, successBlock, appendContents);
    }
  });
}

// In the autocomplete method the results are not added to the name to content, they are independent
function getAutocompleteContents(params, callback) {
  post({
    data: params,
    url: "/api/v5/get_searchable_contents",
    success: (data) => callback(data)
  });
}

function getUserById(userId) {
  const user = globalState.pageModel.id_to_user[userId];
  return exists(user) ? user : { anonymous: true };
}

function getUserAvatarById(userId) {
  if (isUserRegistered() && globalState.pageModel.user.id == userId) {
    return getUserAvatarByUser(globalState.pageModel.user);
  } else {
    return getUserAvatarByUser(getUserById(userId));
  }
}

function getUsernameById(userId) {
  return getUsernameByUser(getUserById(userId));
}

function getUsernameByUser(user) {
  if (user.anonymous) {
    return fandomTranslate("globals.anonymous_username");
  } else {
    if (user.first_name && user.last_name) {
      return `${user.first_name} ${user.last_name.charAt(0)}.`;
    } else {
      return user.username.split("@")[0];
    }
  }
}

function getUserAvatarByUser(user) {
  if (user.avatar) {
    return typeof user.avatar == "string" ? user.avatar : user.avatar.url;
  } else {
    return null;
  }
}

function getContentsCallback(contentName, data, successBlock, appendContents = false, forceContentUpdate=false) {
  const newContent = data.name_to_content[contentName];
  let oldContent = globalState.pageModel.name_to_content[contentName];
  const oldContentChildren = get(oldContent, ['children'], []);

  // we may not have the content in particular cases (getSearchableContents)
  oldContent = oldContent || newContent;

  if (forceContentUpdate) {
    Vue.set(globalState.pageModel.name_to_content, contentName, newContent);
  }

  if (appendContents) {
    Vue.set(oldContent, 'children', concat(oldContentChildren, newContent.children));
  } else {
    oldContentChildren.forEach(contentName => {
      if (get(globalState.pageModel, ['name_to_content', contentName], false)) {
        Vue.delete(globalState.pageModel.name_to_content, contentName);
      }
    })
    Vue.set(oldContent, 'children', newContent.children ? newContent.children.slice(0) : []);
    oldContent.children_count = newContent.children_count;
  }
  oldContent.has_more_children = newContent.has_more_children;

  Object.keys(data.name_to_content).forEach(key => {
    const contentPresence = Fandom.exists(globalState.pageModel.name_to_content[key])
    if (!contentPresence) {
      Vue.set(globalState.pageModel.name_to_content, key, data.name_to_content[key]);
    }
  });
  
  const newNameToContentUserInfo = {};
  merge(newNameToContentUserInfo, globalState.pageModel.name_to_content_user_info, data.name_to_content_user_info)
  Vue.set(globalState.pageModel,'name_to_content_user_info', newNameToContentUserInfo);

  const newNameToContentAuthorization = {};
  merge(newNameToContentAuthorization, globalState.pageModel.name_to_content_authorization, data.name_to_content_authorization)
  Vue.set(globalState.pageModel,'name_to_content_authorization', newNameToContentAuthorization);

  const newIdToUser = {};
  merge(newIdToUser, globalState.pageModel.id_to_user, data.id_to_user);
  Vue.set(globalState.pageModel,'id_to_user', newIdToUser);

  const newDecorators = {};
  merge(newDecorators, globalState.pageModel.decorators, data.decorators);
  Vue.set(globalState.pageModel,'decorators', newDecorators);

  if (exists(successBlock)) {
    successBlock(data);
  }
}

function isContentVisibleToUser() {
  let isVisible = !(isUserRegistered() ? this.content.hide_to_registered_user : this.content.hide_to_anonymous_user);
  if (this.content.hide_for_device) {
    let hideOn = this.content.hide_for_device.split(",");
    isVisible = !((((globalState.viewport.xs || globalState.viewport.sm) && hideOn.includes("mobile")) || ((globalState.viewport.md || globalState.viewport.lg) && hideOn.includes("tablet"))) || (globalState.viewport.xl && hideOn.includes("desktop")));
  }
  return isVisible;
}

function getTileThumbnailUrl() {
  let url = exists(this.content.thumbnail) ? getContentResponsiveImageURL.call(this, "thumbnail") : getContentResponsiveImageURL.call(this, "image");
  if (exists(url)) {
    url = url.replace('/original/', '/thumb/');
  }
  return url;
}

function getContentResponsiveImage(name, customContent = false) {
  const content = customContent ? customContent : this.content;
  if (globalState.viewport["xs"] && exists(content[`${name}_mobile`])) {
    return expandUrl(content[`${name}_mobile`].url);
  } else if (exists(content[name])) {
    return expandUrl(content[name].url);
  }
  return null;
}

function getContentResponsiveImageURL(name, customContent = false) {
  const imageUrl = getContentResponsiveImage.call(this, name, customContent);
  return exists(imageUrl) ? `url("${imageUrl}")` : null;
}

function getOptContentResponsiveImage(name) {
  if (globalState.viewport["xs"] && exists(this.optContainerContent[`${name}_mobile`])) {
    return expandUrl(this.optContainerContent[`${name}_mobile`].url);
  } else if (exists(this.optContainerContent[name])) {
    return expandUrl(this.optContainerContent[name].url);
  }
  return null;
}

function getOptContentResponsiveImageURL(name) {
  const imageUrl = getOptContentResponsiveImage.call(this, name);
  return exists(imageUrl) ? `url("${imageUrl}")` : null;
}

// TODO move in interaction
function facebookUrl() {
  const url = `/auth/facebook_${globalState.pageModel.tenant.id}`;
  const context = globalState.pageModel.context;
  let params = [];
  for(let key of Object.keys(context)) {
    const contextStringValue = get(context, [key, "name"], false);
    if (contextStringValue) {
      params.push(`${key}=${contextStringValue}`);
    }
  }
  return (params.length > 0) ? `${url}?${params.join("&")}` : url;
}

// Careful, this solution does a deepClone but it doesn't work in some edge cases (eg with a regex)
// Use lodash cloneDeep in this cases, it is slower by an huge margin (~ +50%) but it works in every case
// Form more info see https://stackoverflow.com/a/122704
function deepClone(item) {
  return JSON.parse(JSON.stringify(item));
}

function getTarget(field) {
  const newTab = get(field, "new_tab", false);
  return newTab ? "_blank" : "_self";
}

function getRelNoFollow(field) {
  const noFollow = get(field, "no_follow", false);
  return noFollow ? "nofollow" : "";
}

// getContent find content by name in name_to_content
function getContent(name) {
  return globalState.pageModel.name_to_content[name];
}

function getDecorator(content, categoryClass) {
  categoryClass = categoryClass || content.breadcrumb_class;
  const decoratorToContent = get(globalState, ["pageModel","decorators",categoryClass]);
  const categoryClassToDecorator = content.decorators;
  if (exists(decoratorToContent) && exists(categoryClassToDecorator)) {
    const decorator = categoryClassToDecorator[categoryClass];
    return decoratorToContent[decorator];
  }
  return null;
}

function getProviderIcon(name) {
  if (name === "facebook") {
    return "fa-facebook-square";
  }
  return `fa-${name.replace(/_/, "-")}`;
}


function copyToClipboard(text) {
  const el = document.createElement("textarea");
  el.value = text;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
}

// A simple Vue component used just to dispatch events. It needs to be initialized before any other component,
// including the vue root, to guarantee that event dispatching is available on mounting.
const EventHub = new Vue();
function emit(eventName, data) {
  return EventHub.$emit(eventName, data);
}
function onEvent(event, callback) {
  return EventHub.$on(event, callback);
}
function offEvent(event) {
  return EventHub.$off(event);
}

function updateQueryStringParameter(uri, key, value) {
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, "i");
  const separator = uri.indexOf("?") !== -1 ? "&" : "?";
  let baseUri = decodeURI(uri);
  if (uri.match(re)) {
    baseUri = exists(value) ? uri.replace(re, `$1${key}=${value}$2`) : uri.replace(re, "$1");
  } else if (value) {
    baseUri = `${uri}${separator}${key}=${value}`;
  }
  return encodeURI(baseUri.replace(/[^=&]+=(&|$)/g, "").replace(/&$/, ""));
}

// Converts the input search string to the full text search syntax used by PostgreSQL
function searchTextToBackendFormat(searchText) {
  return searchText;
  // if (exists(searchText) && !isEmpty(searchText)) {
  //   const words = searchText.trim().split(/\s+/);
  //   const lexemes = words; // warning: words.map(w => w + ":*"); too many false positive results
  //   return lexemes.join(" & ");
  // } else {
  //   return searchText;
  // }
}

function getButtonSettings(key) {
  return get(retrieveFromGlobalState("button_settings", false), key, false);
}

function getPrimaryButtonClass(buttonClass, customTheme) {
  if (exists(buttonClass)) {
    return buttonClass;
  }
  const theme = exists(customTheme) ? customTheme : "light";
  const btn_class = getButtonSettings("button_primary_class");
  if (btn_class && btn_class[theme]) {
    return btn_class[theme];
  } else if (btn_class) {
    return btn_class;
  } else {
    return "btn-primary";
  }
}

function getSecondaryButtonClass(buttonClass, customTheme) {
  if (exists(buttonClass)) {
    return buttonClass;
  }
  const theme = exists(customTheme) ? customTheme : "light";
  const btn_class = getButtonSettings("button_secondary_class");
  if (btn_class && btn_class[theme]) {
    return btn_class[theme];
  } else if (btn_class) {
    return btn_class;
  } else {
    return "btn-secondary";
  }
}

function getButtonClass(buttonClass, defaultButtonClass) {
  return exists(buttonClass) ? buttonClass : defaultButtonClass;
}

function registerAnimation(el) {
  el = el || $(this.$el);
  el.one("inview", function(event, isInView) {
    if (isInView) {
      const animationClass = "inview_animate_in";
      const target = el;

      target.addClass(animationClass);
      target.find(".inview_animate_zoom").each((i, el) => {
        $(el).addClass("inview_animate_zoom_in");
      });
    }

  });
}

function alignTextToJustify(direction) {
  const map = {
    'left': 'begin',
    'center': 'center',
    'right': 'end'
  }

  if (!direction) {
    return 'center';
  }
  return map[direction];
}

function jsEnabled() {
  document.body.classList.add("js");
}

function toggleHeaderFooter() {
  $(document.body).toggleClass('fullscreen');
}

function getRecordOrContent(extraField) {
  if (typeof extraField == 'string') {
    return get(globalState.pageModel, ['name_to_content', extraField], false);
  } else {
    return extraField;
  }
}

function executeForEachExtraField(extraField, cb) {
  if (extraField instanceof Object) {
    Object.keys(extraField).forEach(key => {
      cb(key, extraField[key]);
      executeForEachExtraField(extraField[key], cb);
    });
  }
}

// exports
const Fandom = {
  flickityIOsFix,
  jsEnabled,
  toggleHeaderFooter,
  registerAnimation,
  urljoin,
  getAutocompleteContents,
  getSearchableContents,
  getRandomContents,
  getContentsByNames,
  setCookie,
  deepClone,
  facebookUrl,
  getContextPath,
  acceptCookies,
  applyContextToUrl,
  ajax,
  post,
  deleteCookie,
  executeOnce,
  exists,
  expandUrl,
  getBackgroundUrl,
  getNextContent,
  getTarget,
  getRelNoFollow,
  getContent,
  getContents,
  getDecorator,
  getUrlParameter,
  getJSONUrlParameter,
  isSafari,
  isTouchSupported,
  executeForEachExtraField,
  readCookie,
  scrollToBottom,
  scrollToTop,
  isContentVisibleToUser,
  getOptContentResponsiveImage,
  getOptContentResponsiveImageURL,
  getContentResponsiveImage,
  getContentResponsiveImageURL,
  getTileThumbnailUrl,
  isUserRegistered,
  appendContents,
  getContentURL,
  setUserReward,
  getUserReward,
  getMainRewardName,
  getUserMainRewardCount,
  setUserMainRewardCount,
  getProviderIcon,
  retrieveFromGlobalState,
  emit,
  onEvent,
  offEvent,
  isIOsDevice,
  updateQueryStringParameter,
  searchTextToBackendFormat,
  getUserById,
  getUsernameById,
  getUserAvatarById,
  mayGetAudioAsset,
  getUsernameByUser,
  getUserAvatarByUser,
  getCurrencyCount,
  setCurrencyCount,
  getButtonClass,
  getPrimaryButtonClass,
  getSecondaryButtonClass,
  executeOnceIds,
  repeatUntil,
  getContentsCallback,
  alignTextToJustify,
  isTouchDevice,
  isImage,
  isAudio,
  capitalize,
  getRecordOrContent,
  copyToClipboard,
  sendFandomFacebookAnalytics,
  getQueryStringParams,
  retry,
  sleep,
  getCSRFHeader
};

export { Fandom };
