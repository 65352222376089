<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';
import { interactionMixin } from "src/modules/interactions_module.js";
import Breadcrumb from 'breadcrumb';
import { get } from "lodash";

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, interactionMixin],
  components: {Breadcrumb},
  computed: {
    interactionDet() {
      let result = {
        present: false,
        complete: false,
        counter: 0
      };

      const rewardingContents = [this.containerContent.name];
      if (this.containerContent.stripes) {
        this.containerContent.stripes.forEach(content => {
          if (content.layout == "shared-content") {
            rewardingContents.push(content.content);
          }
        });
      }
      
      rewardingContents.forEach(name => {
        const contentUserInfo = globalState.pageModel.name_to_content_user_info[name];
        if (Fandom.exists(contentUserInfo)) {
          const status = this.contentUserInfoStatus(this.containerContent);
          const counter = get(status, ["reward_name_to_counter", "point"], 0);

          result.complete = result.complete || get(status, ["complete"], false);
          result.counter += counter;
          result.present = result.present || counter > 0;
        }
      });

      return result;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :class="contentType" :id="anchorId" :style="theme" v-if="isContentVisibleToUser()" class="themed-item" page-article-component>
    <div class="interaction-det themed-item-reverse text-center" v-if="interactionDet.present">
      <div v-if="interactionDet.complete"><i class="fal fa-check"></i></div>
      <div v-else><span>{{interactionDet.counter}} <i class="fal fa-sack-dollar"></i></span></div>
    </div>
    <div v-if="content.breadcrumb">
      <breadcrumb/>
    </div>
    <component :is="stripe.layout" :content="stripe" v-for="stripe in content.stripes" :key="stripe.$id" :opt-container-content="containerContent"></component>
  </div>
</template>

<style lang="scss">
[page-article-component] {
  .interaction-det {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    font-size: 1.25rem;
    border-radius: 100%;
    z-index: 1090;
  }
}
</style>
